import React from 'react';
import { compose, withProps } from 'recompose';
import { Paper } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import styled from 'styled-components';
import { graphql } from 'react-relay';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { withTranslation as translate } from 'react-i18next';

import DictionaryList from './DictionaryList';
import { withQuery } from '../../../../utils/relay';

import { createRegion, updateRegion, deleteRegion } from '../mutations/regions';
import {
  createPipelineStage,
  updatePipelineStage,
  deletePipelineStage,
} from '../mutations/pipelineStages';
import {
  createOrganisationType,
  updateOrganisationType,
  deleteOrganisationType,
} from '../mutations/organisationType';
import {
  createUseType,
  updateUseType,
  deleteUseType,
} from '../mutations/useTypes';
import {
  createDealStatus,
  deleteDealStatus,
  updateDealStatus,
} from '../mutations/dealStatuses';
import {
  createFileType,
  updateFileType,
  deleteFileType,
} from '../mutations/fileTypes';
import {
  createNotInterestedReason,
  updateNotInterestedReason,
  deleteNotInterestedReason,
} from '../mutations/notInterestedReasons';
import {
  createDealProgressStage,
  updateDealProgressStage,
  deleteDealProgressStage,
} from '../mutations/dealProgressStages';

import { TitleProvider } from '../../controls/Title';
import {
  createDealPipelineStage,
  deleteDealPipelineStage,
  updateDealPipelineStage,
} from '../mutations/dealPipelineStages';

const Root = styled.div`
  display: flex;
  flex: 1;
`;

const UList = styled(List)`
  min-width: ${props => (props['data-thin'] ? '300px' : '100%')};
`;

const UListItem = styled(ListItem)`
  background-color: ${props =>
    props['data-active'] ? props.theme.palette.action.selected : 'transparent'};
`;

const DictionariesTab = ({ dictionaries, match, isWide }) => (
  <Root>
    <Route
      path={`${match.url}/:type?`}
      render={({ match: { params, url } }) => {
        const type = dictionaries.find(item => item.url === params.type);

        // redirect to first available dictionary
        if (!type && isWide)
          return <Redirect to={`${url}/${dictionaries[0].url}`} />;

        // hide navigation if mobile layout
        if (type && !isWide) return null;

        return (
          <UList component={isWide ? Paper : 'nav'} data-thin={isWide}>
            {dictionaries.map(item => (
              <UListItem
                data-active={item.url === params.type}
                key={item.url}
                button
                component={Link}
                to={`${match.url}/${item.url}`}
              >
                <ListItemText primary={item.title} />
              </UListItem>
            ))}
          </UList>
        );
      }}
    />
    <Switch>
      {dictionaries.map(item => (
        <Route
          key={item.url}
          exact
          path={`${match.url}/${item.url}`}
          render={() => (
            <TitleProvider text={item.title}>
              <DictionaryList isWide={isWide} {...item} />
            </TitleProvider>
          )}
        />
      ))}
    </Switch>
  </Root>
);

const enhancer = compose(
  translate('settings'),
  withQuery(
    graphql`
      query DictionariesTabQuery {
        useTypes {
          ...DictionaryList_items
        }
        organisationTypes {
          ...DictionaryList_items
        }
        regions {
          ...DictionaryList_items
        }
        pipelineStages {
          ...DictionaryList_items
        }
        fileTypes {
          ...DictionaryList_items
        }
        dealStatus {
          ...DictionaryList_items
        }
        notInterestedReasons {
          ...DictionaryList_items
        }
        dealProgressStages {
          ...DictionaryList_items
        }
        dealPipelineStages {
          ...DictionaryList_items
        }
      }
    `,
    {
      renderLoading: true,
    },
  ),
  withRouter,
  withWidth(),
  withProps(({ data, width, t }) => ({
    isWide: isWidthUp('md', width),
    dictionaries: [
      {
        title: t('regions'),
        url: 'regions',
        items: data.regions,
        updateMutation: updateRegion,
        createMutation: createRegion,
        deleteMutation: deleteRegion,
        typename: 'Region',
      },
      {
        title: t('useTypes'),
        url: 'use-types',
        items: data.useTypes,
        updateMutation: updateUseType,
        createMutation: createUseType,
        deleteMutation: deleteUseType,
        typename: 'UseType',
      },
      {
        title: t('organisationTypes'),
        url: 'organisation-types',
        items: data.organisationTypes,
        updateMutation: updateOrganisationType,
        createMutation: createOrganisationType,
        deleteMutation: deleteOrganisationType,
        typename: 'OrganisationType',
      },
      {
        title: t('investorPipelineStages'),
        url: 'investor-pipeline-stages',
        items: data.pipelineStages,
        updateMutation: updatePipelineStage,
        createMutation: createPipelineStage,
        deleteMutation: deletePipelineStage,
        typename: 'PipelineStage',
      },
      {
        title: t('dealPipelineStages'),
        url: 'deal-pipeline-stages',
        items: data.dealPipelineStages,
        updateMutation: updateDealPipelineStage,
        createMutation: createDealPipelineStage,
        deleteMutation: deleteDealPipelineStage,
        typename: 'DealPipelineStage',
      },
      {
        title: t('dealStatuses'),
        url: 'deal-statuses',
        items: data.dealStatus,
        updateMutation: updateDealStatus,
        createMutation: createDealStatus,
        deleteMutation: deleteDealStatus,
        typename: 'DealStatus',
      },
      {
        title: t('fileTypes'),
        url: 'file-types',
        items: data.fileTypes,
        updateMutation: updateFileType,
        createMutation: createFileType,
        deleteMutation: deleteFileType,
        typename: 'FileType',
      },
      {
        title: t('notInterestedReasons'),
        url: 'not-interested-reasons',
        items: data.notInterestedReasons,
        updateMutation: updateNotInterestedReason,
        createMutation: createNotInterestedReason,
        deleteMutation: deleteNotInterestedReason,
        typename: 'NotInterestedReasonType',
      },
      {
        title: t('dealProgress'),
        url: 'deal-progress-stages',
        items: data.dealProgressStages,
        updateMutation: updateDealProgressStage,
        createMutation: createDealProgressStage,
        deleteMutation: deleteDealProgressStage,
        typename: 'DealProgressStageType',
      },
    ],
  })),
);

export default enhancer(DictionariesTab);
