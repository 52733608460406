/**
 * @flow
 * @relayHash e8cf0ad2cdf608665d85018fe529366f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DictionaryList_items$ref = any;
export type DictionariesTabQueryVariables = {||};
export type DictionariesTabQueryResponse = {|
  +useTypes: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +organisationTypes: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +regions: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +pipelineStages: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +fileTypes: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +dealStatus: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +notInterestedReasons: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +dealProgressStages: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
  +dealPipelineStages: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DictionaryList_items$ref
  |}>,
|};
export type DictionariesTabQuery = {|
  variables: DictionariesTabQueryVariables,
  response: DictionariesTabQueryResponse,
|};
*/


/*
query DictionariesTabQuery {
  useTypes {
    ...DictionaryList_items
    id
  }
  organisationTypes {
    ...DictionaryList_items
    id
  }
  regions {
    ...DictionaryList_items
    id
  }
  pipelineStages {
    ...DictionaryList_items
    id
  }
  fileTypes {
    ...DictionaryList_items
    id
  }
  dealStatus {
    ...DictionaryList_items
    id
  }
  notInterestedReasons {
    ...DictionaryList_items
    id
  }
  dealProgressStages {
    ...DictionaryList_items
    id
  }
  dealPipelineStages {
    ...DictionaryList_items
    id
  }
}

fragment DictionaryList_items on Dictionary {
  id
  position
  ... on PipelineStage {
    group
  }
  ... on DealStatus {
    group
  }
  ... on FileType {
    group
  }
  ...DictionaryItem_item
}

fragment DictionaryItem_item on Dictionary {
  __typename
  id
  label
  position
  labelEn: label(lang: "en")
  labelFr: label(lang: "fr")
  labelDe: label(lang: "de")
  ... on PipelineStage {
    group
  }
  ... on DealStatus {
    group
  }
  ... on FileType {
    group
    type
    adminOnlyAccess
    pipelineStageAccess {
      SELECTED_NOTIFIED
      INTERESTED
      NDA_SIGNED
      NBO_SUBMITTED
      VISIT
      DUE_DILIGENCE
      BO_SUBMITTED
      CLOSING
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "FragmentSpread",
    "name": "DictionaryList_items",
    "args": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": "labelEn",
  "name": "label",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "en"
    }
  ],
  "storageKey": "label(lang:\"en\")"
},
v6 = {
  "kind": "ScalarField",
  "alias": "labelFr",
  "name": "label",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "fr"
    }
  ],
  "storageKey": "label(lang:\"fr\")"
},
v7 = {
  "kind": "ScalarField",
  "alias": "labelDe",
  "name": "label",
  "args": [
    {
      "kind": "Literal",
      "name": "lang",
      "value": "de"
    }
  ],
  "storageKey": "label(lang:\"de\")"
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "adminOnlyAccess",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pipelineStageAccess",
  "storageKey": null,
  "args": null,
  "concreteType": "FileTypePipelineStageAccess",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "SELECTED_NOTIFIED",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "INTERESTED",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "NDA_SIGNED",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "NBO_SUBMITTED",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "VISIT",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "DUE_DILIGENCE",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "BO_SUBMITTED",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "CLOSING",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "kind": "InlineFragment",
    "type": "PipelineStage",
    "selections": (v9/*: any*/)
  },
  {
    "kind": "InlineFragment",
    "type": "DealStatus",
    "selections": (v9/*: any*/)
  },
  {
    "kind": "InlineFragment",
    "type": "FileType",
    "selections": [
      (v8/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ]
  }
],
v14 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v8/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "kind": "InlineFragment",
    "type": "FileType",
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DictionariesTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "useTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "UseType",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organisationTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganisationType",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "regions",
        "storageKey": null,
        "args": null,
        "concreteType": "Region",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pipelineStages",
        "storageKey": null,
        "args": null,
        "concreteType": "PipelineStage",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fileTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "FileType",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealStatus",
        "storageKey": null,
        "args": null,
        "concreteType": "DealStatus",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notInterestedReasons",
        "storageKey": null,
        "args": null,
        "concreteType": "NotInterestedReason",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealProgressStages",
        "storageKey": null,
        "args": null,
        "concreteType": "DealProgressStage",
        "plural": true,
        "selections": (v0/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealPipelineStages",
        "storageKey": null,
        "args": null,
        "concreteType": "DealPipelineStage",
        "plural": true,
        "selections": (v0/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DictionariesTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "useTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "UseType",
        "plural": true,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organisationTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganisationType",
        "plural": true,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "regions",
        "storageKey": null,
        "args": null,
        "concreteType": "Region",
        "plural": true,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pipelineStages",
        "storageKey": null,
        "args": null,
        "concreteType": "PipelineStage",
        "plural": true,
        "selections": (v14/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fileTypes",
        "storageKey": null,
        "args": null,
        "concreteType": "FileType",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v8/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealStatus",
        "storageKey": null,
        "args": null,
        "concreteType": "DealStatus",
        "plural": true,
        "selections": (v14/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notInterestedReasons",
        "storageKey": null,
        "args": null,
        "concreteType": "NotInterestedReason",
        "plural": true,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealProgressStages",
        "storageKey": null,
        "args": null,
        "concreteType": "DealProgressStage",
        "plural": true,
        "selections": (v13/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dealPipelineStages",
        "storageKey": null,
        "args": null,
        "concreteType": "DealPipelineStage",
        "plural": true,
        "selections": (v13/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DictionariesTabQuery",
    "id": null,
    "text": "query DictionariesTabQuery {\n  useTypes {\n    ...DictionaryList_items\n    id\n  }\n  organisationTypes {\n    ...DictionaryList_items\n    id\n  }\n  regions {\n    ...DictionaryList_items\n    id\n  }\n  pipelineStages {\n    ...DictionaryList_items\n    id\n  }\n  fileTypes {\n    ...DictionaryList_items\n    id\n  }\n  dealStatus {\n    ...DictionaryList_items\n    id\n  }\n  notInterestedReasons {\n    ...DictionaryList_items\n    id\n  }\n  dealProgressStages {\n    ...DictionaryList_items\n    id\n  }\n  dealPipelineStages {\n    ...DictionaryList_items\n    id\n  }\n}\n\nfragment DictionaryList_items on Dictionary {\n  id\n  position\n  ... on PipelineStage {\n    group\n  }\n  ... on DealStatus {\n    group\n  }\n  ... on FileType {\n    group\n  }\n  ...DictionaryItem_item\n}\n\nfragment DictionaryItem_item on Dictionary {\n  __typename\n  id\n  label\n  position\n  labelEn: label(lang: \"en\")\n  labelFr: label(lang: \"fr\")\n  labelDe: label(lang: \"de\")\n  ... on PipelineStage {\n    group\n  }\n  ... on DealStatus {\n    group\n  }\n  ... on FileType {\n    group\n    type\n    adminOnlyAccess\n    pipelineStageAccess {\n      SELECTED_NOTIFIED\n      INTERESTED\n      NDA_SIGNED\n      NBO_SUBMITTED\n      VISIT\n      DUE_DILIGENCE\n      BO_SUBMITTED\n      CLOSING\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8921dfff5449d706575aa918676467c3';
module.exports = node;
