// @flow

import * as React from 'react';
import { useTheme } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import { SelectPopover } from './select-popover.js';
import { Box, Flex } from 'react-system';

type FilterDialogProps = {|
  bg?: string,
  title: React.Node,
  onClose: (SyntheticEvent<EventTarget>) => void,
  children: React.Node,
|};

const FilterDialog = (props: FilterDialogProps): React.Node => {
  const { typography, palette } = useTheme();

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        css={{
          background: props.bg ?? palette.primary.light,
          color: '#fff',
        }}
      >
        <Box py={2} px={3} css={typography.h6}>
          {props.title}
        </Box>
        <Box
          p={1}
          mr={2}
          css={{ lineHeight: 0, cursor: 'pointer' }}
          onClick={props.onClose}
        >
          <IconClose />
        </Box>
      </Flex>
      {props.children}
    </>
  );
};
type Props = {|
  empty?: boolean,
  label: string,
  dialogTitle: React.Node,
  onReset?: () => void,
  children: React.Node,
|};

export const Filter = (props: Props): React.Node => {
  const [open, setOpen] = React.useState(false);

  return (
    <SelectPopover
      label={props.label}
      empty={props.empty}
      open={open}
      onOpenChange={setOpen}
      onClear={props.onReset}
    >
      <FilterDialog title={props.dialogTitle} onClose={() => setOpen(false)}>
        {props.children}
      </FilterDialog>
    </SelectPopover>
  );
};
