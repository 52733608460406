/**
 * @flow
 * @relayHash 60f2e6cbf00e6708baa15408a2e3852d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UsersList_root$ref = any;
export type SORT_DIRECTION = "ASC" | "DESC" | "%future added value";
export type UserFilters = {|
  organisations?: ?$ReadOnlyArray<?string>,
  cities?: ?$ReadOnlyArray<?string>,
  jobTitles?: ?$ReadOnlyArray<?string>,
  languages?: ?$ReadOnlyArray<?string>,
  isActive?: ?boolean,
  isAdmin?: ?boolean,
  isDealBrokers?: ?boolean,
  dealBrokersCreatedUserId?: ?string,
  isPrimaryContactOfOrgSeller?: ?boolean,
|};
export type UsersQueryVariables = {|
  count: number,
  cursor?: ?string,
  sortBy?: ?string,
  sortDirection?: ?SORT_DIRECTION,
  filters?: ?UserFilters,
|};
export type UsersQueryResponse = {|
  +$fragmentRefs: UsersList_root$ref
|};
export type UsersQuery = {|
  variables: UsersQueryVariables,
  response: UsersQueryResponse,
|};
*/


/*
query UsersQuery(
  $count: Int!
  $cursor: String
  $sortBy: String
  $sortDirection: SORT_DIRECTION
  $filters: UserFilters
) {
  ...UsersList_root
}

fragment UsersList_root on Query {
  users(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {
    edges {
      node {
        id
        firstName
        lastName
        jobTitle
        email
        primaryImage {
          id
          url
        }
        lastSeen
        organisation {
          name
          city
          primaryImage {
            url
            id
          }
          isBroker
          isSupervisor
          isInvestor
          isSeller
          id
        }
        ...UserAvatar_user
        __typename
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortDirection",
    "type": "SORT_DIRECTION",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "UserFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UsersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "UsersList_root",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UsersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobTitle",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "primaryImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastSeen",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organisation",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "city",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "primaryImage",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Image",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isBroker",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isSupervisor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isInvestor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isSeller",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "users",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "Connection_users",
        "filters": [
          "sortBy",
          "sortDirection",
          "filters"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UsersQuery",
    "id": null,
    "text": "query UsersQuery(\n  $count: Int!\n  $cursor: String\n  $sortBy: String\n  $sortDirection: SORT_DIRECTION\n  $filters: UserFilters\n) {\n  ...UsersList_root\n}\n\nfragment UsersList_root on Query {\n  users(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        jobTitle\n        email\n        primaryImage {\n          id\n          url\n        }\n        lastSeen\n        organisation {\n          name\n          city\n          primaryImage {\n            url\n            id\n          }\n          isBroker\n          isSupervisor\n          isInvestor\n          isSeller\n          id\n        }\n        ...UserAvatar_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    totalCount\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7888077da42ab18282aa9e9af82a2467';
module.exports = node;
