import React from 'react';
import Loadable from 'react-loadable';
import { QueryRenderer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { compose, getContext, defaultProps } from 'recompose';
import { withTranslation as translate } from 'react-i18next';

import Loading from '../controls/Loading';
import preloadData from '../../../utils/relay/preloadData';
import TitleProvider from '../controls/Title/TitleProvider';

export const LoadableOrganisationsList = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'Organisations' */ './OrganisationsList'),
  loading: Loading,
});

const organisationsQueryRenderer = ({
  cacheConfig,
  api: { environment },
  query,
  variables,
  filters,
  search,
  t,
  rootViewer,
}) => (
  <TitleProvider text={t('organisations')}>
    <QueryRenderer
      cacheConfig={cacheConfig}
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props }) => {
        if (error) return <div>{error.message}</div>;

        // provide null so internal routes can be loaded even with some defaults
        return (
          <LoadableOrganisationsList
            root={props || null}
            rootViewer={rootViewer}
            filters={filters}
            search={search}
          />
        );
      }}
    />
  </TitleProvider>
);

const query = graphql`
  query OrganisationsQuery(
    $count: Int!
    $cursor: String
    $sortBy: String
    $sortDirection: SORT_DIRECTION
    $filters: OrganisationFilters
    $search: String
  ) {
    ...OrganisationsList_root
  }
`;

const filters = {
  organisationTypes: [],
  cities: [],
  countries: [],
  // assetSize: null,
  lexKollerCompliant: null,
  regions: [],
  useTypes: [],
  numberOfContacts: [],
  assetsUnderManagement: null,
  isActive: true,
};

const cacheConfig = { cacheFirst: true };
const variables = { count: 80, filters: { isActive: true } }; // we need to have isActive: true by default
const search = '';

export const preload = environment =>
  preloadData(environment, query, variables, cacheConfig);

export default compose(
  translate('organisations'),
  defaultProps({
    cacheConfig,
    variables,
    query,
    filters,
    search,
  }),
  getContext({
    api: PropTypes.object,
  }),
)(organisationsQueryRenderer);
