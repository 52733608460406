import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { compose, defaultProps, getContext } from 'recompose';
import Loading from '../controls/Loading';
import TitleProvider from '../controls/Title/TitleProvider';
import { useDealsLeadsParams, paramsToFilters } from './deals-filters';

export const LoadableDealsList = Loadable({
  loader: () => import(/* webpackChunkName: 'Deals' */ './DealsList'),
  loading: Loading,
});

const dealsQueryRenderer = ({ api: { environment }, query, rootViewer }) => {
  const [params, setParams] = useDealsLeadsParams();
  const sortDirection = params.sortDirection ?? 'DESC';
  const sortBy = params.sortBy ?? 'activity';
  const filters = paramsToFilters(params);

  return (
    <TitleProvider text="Deals">
      <QueryRenderer
        // cacheConfig={{ cacheFirst: true }}
        environment={environment}
        query={query}
        variables={{
          count: 80,
          filters,
          sortBy,
          sortDirection,
        }}
        render={({ error, props }) => {
          if (error) return <div>{error.message}</div>;

          // provide null so internal routes can be loaded even with some defaults
          return (
            <LoadableDealsList
              root={props || null}
              filters={filters}
              sortBy={sortBy}
              sortDirection={sortDirection}
              setParams={setParams}
              rootViewer={rootViewer}
            />
          );
        }}
      />
    </TitleProvider>
  );
};

export default compose(
  defaultProps({
    query: graphql`
      query DealsQuery(
        $count: Int!
        $cursor: String
        $sortBy: String
        $sortDirection: SORT_DIRECTION
        $filters: DealFilters
      ) {
        ...DealsList_root
      }
    `,
  }),
  getContext({
    api: PropTypes.object,
  }),
)(dealsQueryRenderer);
