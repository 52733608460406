import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';
import { graphql } from 'react-relay';
import { compose, defaultProps } from 'recompose';
import styled from 'styled-components';

import seedColor from '../../utils/color';
import { createFragmentContainerHOC } from '../../utils/relay';

const AvatarSquare = styled(Avatar)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  img {
    object-fit: contain;
  }
`;

const UserAvatar = ({ user, organisation, style = {}, size, ...rest }) => {
  const theme = useTheme();

  if (size) {
    style = {
      ...style,
      height: size,
      width: size,
      fontSize: theme.typography.pxToRem(size / 2),
    };
  }

  // probably loading, show empty grey avatar
  if (!user && !organisation) {
    return (
      <Avatar style={style} {...rest}>
        ...
      </Avatar>
    );
  }

  if (user) {
    // we have image, show it
    const { primaryImage, firstName, lastName, id } = user;
    if (primaryImage && primaryImage.url) {
      return <Avatar src={primaryImage.url} style={style} {...rest} />;
    }
    // no image, make avatar with bg color and user initials
    const firstChar = firstName[0];
    const secondChar = lastName[0] || firstName[1];
    const color = seedColor(id).toHex();
    return (
      <Avatar
        style={{
          backgroundColor: color,
          color: theme.palette.getContrastText(color),
          ...style,
        }}
        {...rest}
      >
        {firstChar}
        {secondChar}
      </Avatar>
    );
  }

  if (organisation) {
    // we have image, show it
    const { primaryImage, name, id } = organisation;
    if (primaryImage && primaryImage.url) {
      return <AvatarSquare src={primaryImage.url} style={style} {...rest} />;
    }
    // no image, make avatar with bg color and organisation initials
    const firstChar = name[0];
    const color = seedColor(id).toHex();
    return (
      <AvatarSquare
        style={{
          backgroundColor: color,
          color: theme.palette.getContrastText(color),
          ...style,
        }}
        {...rest}
      >
        {firstChar}
      </AvatarSquare>
    );
  }

  return null;
};

export default compose(
  defaultProps({
    organisation: null,
    user: null,
  }),
  createFragmentContainerHOC({
    user: graphql`
      fragment UserAvatar_user on User {
        id
        firstName
        lastName
        fullName
        primaryImage {
          url
        }
      }
    `,
    organisation: graphql`
      fragment UserAvatar_organisation on Organisation {
        id
        name
        primaryImage {
          url
        }
      }
    `,
  }),
)(UserAvatar);
