/**
 * @flow
 * @relayHash 90d77abfa76e639f6ba06a455615439a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganisationsList_root$ref = any;
export type SORT_DIRECTION = "ASC" | "DESC" | "%future added value";
export type OrganisationFilters = {|
  dealOrgHasSellerRole?: ?boolean,
  organisationTypes?: ?$ReadOnlyArray<?string>,
  cities?: ?$ReadOnlyArray<?string>,
  countries?: ?$ReadOnlyArray<?string>,
  lexKollerCompliant?: ?boolean,
  regionIds?: ?$ReadOnlyArray<?string>,
  useTypeIds?: ?$ReadOnlyArray<?string>,
  purchaseTypeIds?: ?$ReadOnlyArray<?string>,
  numberOfContacts?: ?$ReadOnlyArray<?InputInterval>,
  assetsUnderManagement?: ?InputInterval,
  isActive?: ?boolean,
  proofRead?: ?boolean,
|};
export type InputInterval = {|
  from?: ?number,
  to?: ?number,
|};
export type OrganisationsQueryVariables = {|
  count: number,
  cursor?: ?string,
  sortBy?: ?string,
  sortDirection?: ?SORT_DIRECTION,
  filters?: ?OrganisationFilters,
  search?: ?string,
|};
export type OrganisationsQueryResponse = {|
  +$fragmentRefs: OrganisationsList_root$ref
|};
export type OrganisationsQuery = {|
  variables: OrganisationsQueryVariables,
  response: OrganisationsQueryResponse,
|};
*/


/*
query OrganisationsQuery(
  $count: Int!
  $cursor: String
  $sortBy: String
  $sortDirection: SORT_DIRECTION
  $filters: OrganisationFilters
  $search: String
) {
  ...OrganisationsList_root
}

fragment OrganisationsList_root on Query {
  viewer {
    id
    isAdmin
    isBroker
    isSuperAdmin
    isInvestor
    isSeller
    organisation {
      id
      isInvestor
      isSeller
      isBroker
    }
  }
  organisations(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters, search: $search) {
    edges {
      node {
        id
        name
        street
        streetNr
        postcode
        city
        country
        usersCount
        assetsUnderManagement
        primaryImage {
          url
          id
        }
        organisationType {
          id
          label
        }
        primaryContact {
          firstName
          lastName
          email
          primaryImage {
            url
            id
          }
          id
        }
        owner {
          id
          fullName
          isBroker
          isInvestor
          isSeller
          isSuperAdmin
          isAdmin
          organisationId
        }
        isActive
        isBroker
        isSupervisor
        isInvestor
        isSeller
        users {
          id
        }
        deals(first: $count, after: $cursor) {
          totalCount
        }
        __typename
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortDirection",
    "type": "SORT_DIRECTION",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "OrganisationFilters",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isBroker",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperAdmin",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isInvestor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSeller",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v9 = [
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/)
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrganisationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrganisationsList_root",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrganisationsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organisation",
            "storageKey": null,
            "args": null,
            "concreteType": "Organisation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organisations",
        "storageKey": null,
        "args": (v9/*: any*/),
        "concreteType": "OrganisationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganisationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Organisation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "street",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "streetNr",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "postcode",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "city",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "country",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "usersCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "assetsUnderManagement",
                    "args": null,
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organisationType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrganisationType",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "primaryContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "owner",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "organisationId",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isActive",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isSupervisor",
                    "args": null,
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "users",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "deals",
                    "storageKey": null,
                    "args": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "concreteType": "DealConnection",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v11/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "organisations",
        "args": (v9/*: any*/),
        "handle": "connection",
        "key": "Connection_organisations",
        "filters": [
          "sortBy",
          "sortDirection",
          "filters",
          "search"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrganisationsQuery",
    "id": null,
    "text": "query OrganisationsQuery(\n  $count: Int!\n  $cursor: String\n  $sortBy: String\n  $sortDirection: SORT_DIRECTION\n  $filters: OrganisationFilters\n  $search: String\n) {\n  ...OrganisationsList_root\n}\n\nfragment OrganisationsList_root on Query {\n  viewer {\n    id\n    isAdmin\n    isBroker\n    isSuperAdmin\n    isInvestor\n    isSeller\n    organisation {\n      id\n      isInvestor\n      isSeller\n      isBroker\n    }\n  }\n  organisations(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters, search: $search) {\n    edges {\n      node {\n        id\n        name\n        street\n        streetNr\n        postcode\n        city\n        country\n        usersCount\n        assetsUnderManagement\n        primaryImage {\n          url\n          id\n        }\n        organisationType {\n          id\n          label\n        }\n        primaryContact {\n          firstName\n          lastName\n          email\n          primaryImage {\n            url\n            id\n          }\n          id\n        }\n        owner {\n          id\n          fullName\n          isBroker\n          isInvestor\n          isSeller\n          isSuperAdmin\n          isAdmin\n          organisationId\n        }\n        isActive\n        isBroker\n        isSupervisor\n        isInvestor\n        isSeller\n        users {\n          id\n        }\n        deals(first: $count, after: $cursor) {\n          totalCount\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    totalCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '402618ab5f9c3068fe47b68b2c72319d';
module.exports = node;
