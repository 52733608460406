// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
} from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';

import type {
  Field,
  UrlSearchParamsHook,
} from '../../../hooks/url-search-params';
import {
  makeField,
  makeUrlSearchParamsHook,
} from '../../../hooks/url-search-params';
import { Filter } from '../../../controls/filter';
import { Box, Flex } from 'react-system';
import { graphql, useQuery } from '../../../controls/relay';
// import { useDebouncedHandler } from '../../../hooks/debounce';
import { UserAvatar } from '../../../components/UserAvatar';
import { useDebouncedHandler } from '../../../hooks/debounce';
import { tableNumberFormat } from '../utils/formatNumber';

import type {
  DealFilters,
  DEAL_CONFIDENCE_TYPE_ENUM,
} from './__generated__/DealsQuery.graphql';
import type { dealsFiltersUsersQuery } from './__generated__/dealsFiltersUsersQuery.graphql';
import type { dealsFiltersOrganisationsQuery } from './__generated__/dealsFiltersOrganisationsQuery.graphql';
import type { dealsFiltersDealStatusQuery } from './__generated__/dealsFiltersDealStatusQuery.graphql';
import type { dealsFiltersDealPipelineStagesQuery } from './__generated__/dealsFiltersDealPipelineStagesQuery.graphql';
import type { dealsFiltersRegionsQuery } from './__generated__/dealsFiltersRegionsQuery.graphql';
import type { dealsFiltersUseTypesQuery } from './__generated__/dealsFiltersUseTypesQuery.graphql';
import type { dealsFiltersExpectedPriceQuery } from './__generated__/dealsFiltersExpectedPriceQuery.graphql';
import type { dealsFiltersCommissionQuery } from './__generated__/dealsFiltersCommissionQuery.graphql';

const deal_confidence_type_of_string = (string): DEAL_CONFIDENCE_TYPE_ENUM => {
  switch (string) {
    case 'high':
    case 'medium':
    case 'low':
      return string;
    default:
      throw new Error(`unsupported DEAL_CONFIDENCE_TYPE_ENUM: ${string}`);
  }
};

const makeNullableListField = name => {
  return makeField<$ReadOnlyArray<string> | null>({
    get: params => {
      const list = params.getAllStrings(name);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings(name, value || []),
  });
};

const makeNullableNumberField = name => {
  return makeField<number | null>({
    get: params => params.getNumber(name),
    set: (params, value) => params.setNumber(name, value),
  });
};

const makeNullableStringField = name => {
  return makeField<string | null>({
    get: params => params.getString(name),
    set: (params, value) => params.setString(name, value),
  });
};

export const useDealsLeadsParams: UrlSearchParamsHook<{|
  sortBy: Field<string | null>,
  sortDirection: Field<'ASC' | 'DESC' | null>,

  brokerId_in: Field<$ReadOnlyArray<string> | null>,
  sellerId_in: Field<$ReadOnlyArray<string> | null>,
  dealStatusId_in: Field<$ReadOnlyArray<string> | null>,
  confidence_in: Field<$ReadOnlyArray<DEAL_CONFIDENCE_TYPE_ENUM> | null>,
  dealPipelineStageId_in: Field<$ReadOnlyArray<string> | null>,
  regionId_in: Field<$ReadOnlyArray<string> | null>,
  useTypeId_in: Field<$ReadOnlyArray<string> | null>,
  expectedSalePrice_gte: Field<number | null>,
  expectedSalePrice_lte: Field<number | null>,
  potentialCommission_gte: Field<number | null>,
  potentialCommission_lte: Field<number | null>,
  signatureQuarter_in: Field<$ReadOnlyArray<string> | null>,
  commissionQuarter_in: Field<$ReadOnlyArray<string> | null>,
|}> = makeUrlSearchParamsHook({
  sortBy: makeNullableStringField('sortBy'),
  sortDirection: makeField<'ASC' | 'DESC' | null>({
    get: params =>
      params.getString('sortDirection') === 'DESC' ? 'DESC' : 'ASC',
    set: (params, value) => params.setString('sortDirection', value),
  }),

  brokerId_in: makeNullableListField('brokerId_in'),
  sellerId_in: makeNullableListField('sellerId_in'),
  dealStatusId_in: makeNullableListField('dealStatusId_in'),
  confidence_in: makeField<$ReadOnlyArray<DEAL_CONFIDENCE_TYPE_ENUM> | null>({
    get: params =>
      params
        .getAllStrings('confidence_in')
        .map(deal_confidence_type_of_string)
        .filter(Boolean),
    set: (params, value) => params.setAllStrings('confidence_in', value ?? []),
  }),
  dealPipelineStageId_in: makeNullableListField('dealPipelineStageId_in'),
  regionId_in: makeNullableListField('regionId_in'),
  useTypeId_in: makeNullableListField('useTypeId_in'),
  expectedSalePrice_gte: makeNullableNumberField('expectedSalePrice_gte'),
  expectedSalePrice_lte: makeNullableNumberField('expectedSalePrice_lte'),
  potentialCommission_gte: makeNullableNumberField('potentialCommission_gte'),
  potentialCommission_lte: makeNullableNumberField('potentialCommission_lte'),
  signatureQuarter_in: makeNullableListField('signatureQuarter_in'),
  commissionQuarter_in: makeNullableListField('commissionQuarter_in'),
});

type Params = $ElementType<$Call<typeof useDealsLeadsParams>, 0>;

export const paramsToFilters = (params: Params): DealFilters => ({
  brokerId_in: params.brokerId_in,
  sellerId_in: params.sellerId_in,
  dealStatusId_in: params.dealStatusId_in,
  confidence_in: params.confidence_in,
  dealPipelineStageId_in: params.dealPipelineStageId_in,
  regionId_in: params.regionId_in,
  useTypeId_in: params.useTypeId_in,
  expectedSalePrice_gte: params.expectedSalePrice_gte,
  expectedSalePrice_lte: params.expectedSalePrice_lte,
  potentialCommission_gte: params.potentialCommission_gte,
  potentialCommission_lte: params.potentialCommission_lte,
  signatureQuarter_in: params.signatureQuarter_in,
  commissionQuarter_in: params.commissionQuarter_in,
});

const toggleFilterItem = (set, item) => {
  const newSet = new Set(set);
  if (newSet.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  const list = Array.from(newSet);
  return list.length === 0 ? null : list;
};

const getLabel = (nodes, ids, idleLabel) => {
  if (ids.size === 0) {
    return idleLabel;
  }
  const firstId = Array.from(ids)[0];
  // $FlowFixMe
  const first = nodes.find(d => d.id === firstId);
  if (first && first.label != null) {
    return ids.size > 1 ? `${first.label} +${ids.size - 1}` : first.label;
  }
  return '...';
};

const NormalFilterContent = ({ nodes, selectedIds, onClick }) => (
  <Box
    width="300px"
    py={2}
    css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
  >
    {nodes.map(item => (
      <Box key={item.id} px={3}>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              color="primary"
              checked={selectedIds.has(item.id)}
              onChange={() => onClick(item.id)}
            />
          }
        />
      </Box>
    ))}
  </Box>
);

const FilterConfidence = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.confidence_in);
  const toggle = id =>
    setParams({
      confidence_in: toggleFilterItem(selectedIds, id)?.map(
        deal_confidence_type_of_string,
      ),
    });

  const sources = [
    {
      id: 'high',
      label: t('confidenceHigh'),
    },
    {
      id: 'medium',
      label: t('confidenceMedium'),
    },
    {
      id: 'low',
      label: t('confidenceLow'),
    },
  ];

  return (
    <Filter
      label={getLabel(sources, selectedIds, t('confidence'))}
      dialogTitle={t('confidence')}
      empty={selectedIds.size === 0}
      onReset={() => setParams({ confidence_in: [] })}
    >
      <NormalFilterContent
        nodes={sources}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterBroker = () => {
  const [t] = useTranslation();
  const [search, setSearch] = React.useState(null);
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersUsersQuery>({
    query: graphql`
      query dealsFiltersUsersQuery($search: String) {
        users(search: $search, first: 30) {
          edges {
            node {
              ...UserAvatar_user
              id
              fullName
              email
            }
          }
        }
        viewer {
          id
          fullName
        }
      }
    `,
    variables: {
      search,
    },
  });

  const users =
    query.props?.users?.edges?.filter(Boolean).map(edge => edge.node) ?? [];
  const usersForLabel = users;
  const viewer = query.props?.viewer;

  const selectedIds = new Set(params.brokerId_in);
  const toggle = id =>
    setParams({ brokerId_in: toggleFilterItem(selectedIds, id) });

  // fast hack to display preloaded user, it's always viewer
  if (
    viewer != null &&
    selectedIds.has(viewer.id) &&
    !users.some(user => user?.id === viewer.id)
  ) {
    usersForLabel.push(viewer);
  }

  return (
    <Filter
      label={getLabel(
        usersForLabel.map(user => ({ label: user?.fullName, id: user?.id })),
        selectedIds,
        t('broker'),
      )}
      dialogTitle={t('broker')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ brokerId_in: null });
        setSearch(null);
      }}
    >
      <Box p={2}>
        <Input
          value={search}
          onChange={evt => setSearch(evt.target.value)}
          placeholder={t('searchUsers')}
          fullWidth={true}
          type="search"
          disableUnderline={true}
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        />
      </Box>
      <Divider />
      <List
        css={`
          width: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 80vh;
        `}
      >
        {query.props?.users ? (
          query.props.users.edges?.filter(Boolean).length ? (
            query.props?.users?.edges
              ?.filter(Boolean)
              .map(edge => edge.node)
              .filter(Boolean)
              .map(user => (
                <ListItem
                  button={true}
                  key={user.id}
                  onClick={() => toggle(user.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={selectedIds.has(user.id)}
                      onChange={() => toggle(user.id)}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <UserAvatar user={user} />
                  </ListItemIcon>
                  <ListItemText
                    css={`
                      overflow: hidden;
                      margin-right: 8px;
                    `}
                    primary={user.fullName}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={user.email}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))
          ) : (
            <Box px={3} py={2}>
              {t('nothingFound')}
            </Box>
          )
        ) : (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </List>
    </Filter>
  );
};

const FilterSeller = () => {
  const [t] = useTranslation();
  const [search, setSearch] = React.useState(null);
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersOrganisationsQuery>({
    query: graphql`
      query dealsFiltersOrganisationsQuery($search: String) {
        organisations(search: $search, first: 30) {
          edges {
            node {
              ...UserAvatar_organisation
              id
              name
              emailAddress
            }
          }
        }
      }
    `,
    variables: {
      search,
    },
  });

  const organisations = query.props?.organisations?.edges ?? [];
  const selectedIds = new Set(params.sellerId_in);
  const toggle = id =>
    setParams({ sellerId_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      label={getLabel(
        organisations.map(edge => ({
          label: edge?.node?.name,
          id: edge?.node?.id,
        })),
        selectedIds,
        t('seller'),
      )}
      dialogTitle={t('seller')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ sellerId_in: null });
        setSearch(null);
      }}
    >
      <Box p={2}>
        <Input
          value={search}
          onChange={evt => setSearch(evt.target.value)}
          placeholder={t('searchOrganisations')}
          fullWidth={true}
          type="search"
          disableUnderline={true}
          autoFocus={true}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        />
      </Box>
      <Divider />
      <List
        css={`
          width: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 80vh;
        `}
      >
        {query.props?.organisations ? (
          query.props.organisations.edges?.length ? (
            query.props.organisations.edges
              .filter(Boolean)
              .map(edge => edge.node)
              .filter(Boolean)
              .map(organisation => (
                <ListItem
                  button={true}
                  key={organisation.id}
                  onClick={() => toggle(organisation.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={selectedIds.has(organisation.id)}
                      onChange={() => toggle(organisation.id)}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <UserAvatar organisation={organisation} />
                  </ListItemIcon>
                  <ListItemText
                    css={`
                      overflow: hidden;
                      margin-right: 8px;
                    `}
                    primary={organisation.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={organisation.emailAddress}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))
          ) : (
            <Box px={3} py={2}>
              {t('nothingFound')}
            </Box>
          )
        ) : (
          <Box>
            <LinearProgress />
          </Box>
        )}
      </List>
    </Filter>
  );
};

const FilterStatus = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersDealStatusQuery>({
    query: graphql`
      query dealsFiltersDealStatusQuery {
        dealStatus {
          id
          label
        }
      }
    `,
  });

  const dealStatuses = query.props?.dealStatus?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.dealStatusId_in);
  const toggle = id =>
    setParams({
      dealStatusId_in: toggleFilterItem(selectedIds, id),
    });

  return (
    <Filter
      label={getLabel(dealStatuses, selectedIds, t('status'))}
      dialogTitle={t('status')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ dealStatusId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealStatuses}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterPipelineStages = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersDealPipelineStagesQuery>({
    query: graphql`
      query dealsFiltersDealPipelineStagesQuery {
        dealPipelineStages {
          id
          label
        }
      }
    `,
  });

  const dealPipelineStages =
    query.props?.dealPipelineStages?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.dealPipelineStageId_in);
  const toggle = id =>
    setParams({
      dealPipelineStageId_in: toggleFilterItem(selectedIds, id),
    });

  return (
    <Filter
      label={getLabel(dealPipelineStages, selectedIds, t('stage'))}
      dialogTitle={t('stage')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ dealPipelineStageId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealPipelineStages}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterRegions = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersRegionsQuery>({
    query: graphql`
      query dealsFiltersRegionsQuery {
        regions {
          id
          label
        }
      }
    `,
  });

  const regions = query.props?.regions?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.regionId_in);
  const toggle = id =>
    setParams({ regionId_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      label={getLabel(regions, selectedIds, t('region'))}
      dialogTitle={t('region')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ regionId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={regions}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterUseType = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersUseTypesQuery>({
    query: graphql`
      query dealsFiltersUseTypesQuery {
        useTypes {
          id
          label
        }
      }
    `,
  });

  const useTypes = query.props?.useTypes?.filter(Boolean) ?? [];
  const selectedIds = new Set(params.useTypeId_in);
  const toggle = id =>
    setParams({ useTypeId_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      label={getLabel(useTypes, selectedIds, t('useType'))}
      dialogTitle={t('useType')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ useTypeId_in: null });
      }}
    >
      <NormalFilterContent
        nodes={useTypes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterExpectedPrice = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersExpectedPriceQuery>({
    query: graphql`
      query dealsFiltersExpectedPriceQuery {
        minDeal: deals(
          first: 1
          sortBy: "expectedSalePrice"
          sortDirection: ASC
        ) {
          edges {
            node {
              id
              expectedSalePrice
            }
          }
        }
        maxDeal: deals(
          first: 1
          sortBy: "expectedSalePrice"
          sortDirection: DESC
        ) {
          edges {
            node {
              id
              expectedSalePrice
            }
          }
        }
      }
    `,
  });

  const min = query.props?.minDeal?.edges?.[0]?.node?.expectedSalePrice ?? 0;
  const max =
    query.props?.maxDeal?.edges?.[0]?.node?.expectedSalePrice ?? 100000000000;

  const [value, setValue] = React.useState([
    params.expectedSalePrice_gte,
    params.expectedSalePrice_lte,
  ]);
  const debouncedSetParams = useDebouncedHandler(300, setParams);

  const getLabel = ([min, max], def) => {
    if (min == null && max == null) {
      return def;
    }
    let str = '';
    if (min != null) {
      str += `${t('from')} ${tableNumberFormat(min)}`;
    }
    if (max != null) {
      str += ` ${t('to')} ${tableNumberFormat(max)}`;
    }

    return str;
  };

  return (
    <Filter
      label={getLabel(
        [params.expectedSalePrice_gte, params.expectedSalePrice_lte],
        t('expectedSalePrice'),
      )}
      dialogTitle={t('expectedSalePrice')}
      empty={
        params.expectedSalePrice_gte == null &&
        params.expectedSalePrice_lte == null
      }
      onReset={() => {
        setParams({ expectedSalePrice_gte: null, expectedSalePrice_lte: null });
        setValue([null, null]);
      }}
    >
      <div
        css={`
          width: 400px;
        `}
      >
        <Box p={3}>
          <Flex justifyContent="space-between">
            <Typography variant="body2">
              {value[0]?.toLocaleString()}
            </Typography>
            <Typography variant="body2">
              {value[1]?.toLocaleString()}
            </Typography>
          </Flex>
          <Slider
            min={min}
            max={max}
            value={[value[0] ?? min, value[1] ?? max]}
            step={10000}
            onChange={(evt, newValue) => {
              setValue(newValue);
              debouncedSetParams({
                expectedSalePrice_gte: newValue[0],
                expectedSalePrice_lte: newValue[1],
              });
            }}
          />
          <Flex justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {min.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {max.toLocaleString()}
            </Typography>
          </Flex>
        </Box>
      </div>
    </Filter>
  );
};

const FilterCommission = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const query = useQuery<dealsFiltersCommissionQuery>({
    query: graphql`
      query dealsFiltersCommissionQuery {
        minDeal: deals(
          first: 1
          sortBy: "commissionCalculated"
          sortDirection: ASC
        ) {
          edges {
            node {
              id
              commissionCalculated
            }
          }
        }
        maxDeal: deals(
          first: 1
          sortBy: "commissionCalculated"
          sortDirection: DESC
        ) {
          edges {
            node {
              id
              commissionCalculated
            }
          }
        }
      }
    `,
  });

  const min = query.props?.minDeal?.edges?.[0]?.node?.commissionCalculated ?? 0;
  const max =
    query.props?.maxDeal?.edges?.[0]?.node?.commissionCalculated ?? 100;

  const [value, setValue] = React.useState([
    params.potentialCommission_gte,
    params.potentialCommission_lte,
  ]);
  const debouncedSetParams = useDebouncedHandler(300, setParams);

  const getLabel = ([min, max], def) => {
    if (min == null && max == null) {
      return def;
    }
    let str = '';
    if (min != null) {
      str += `${t('from')} ${min.toLocaleString()}`;
    }
    if (max != null) {
      str += ` ${t('to')} ${max.toLocaleString()}`;
    }

    return str;
  };

  return (
    <Filter
      label={getLabel(
        [params.potentialCommission_gte, params.potentialCommission_lte],
        t('potentialCommission'),
      )}
      dialogTitle={t('potentialCommission')}
      empty={
        params.potentialCommission_gte == null &&
        params.potentialCommission_lte == null
      }
      onReset={() => {
        setParams({
          potentialCommission_gte: null,
          potentialCommission_lte: null,
        });
        setValue([null, null]);
      }}
    >
      <div
        css={`
          width: 400px;
        `}
      >
        <Box p={3}>
          <Flex justifyContent="space-between">
            <Typography variant="body2">
              {value[0]?.toLocaleString()}
            </Typography>
            <Typography variant="body2">
              {value[1]?.toLocaleString()}
            </Typography>
          </Flex>
          <Slider
            min={min}
            max={max}
            value={value}
            step={1}
            onChange={(evt, newValue) => {
              setValue(newValue);
              debouncedSetParams({
                potentialCommission_gte: newValue[0],
                potentialCommission_lte: newValue[1],
              });
            }}
          />
          <Flex justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {min.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {max.toLocaleString()}
            </Typography>
          </Flex>
        </Box>
      </div>
    </Filter>
  );
};

const GetFilterQuarterOptions = () => {
  const [t] = useTranslation();
  return [
    {
      id: 't1',
      label: t('signatureT1'),
    },
    {
      id: 't2',
      label: t('signatureT2'),
    },
    {
      id: 't3',
      label: t('signatureT3'),
    },
    {
      id: 't4',
      label: t('signatureT4'),
    },
  ];
};

const FilterSignatureQuarter = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.signatureQuarter_in);
  const toggle = id =>
    setParams({
      signatureQuarter_in: toggleFilterItem(selectedIds, id),
    });
  const dealQuarters = GetFilterQuarterOptions();
  return (
    <Filter
      label={getLabel(dealQuarters, selectedIds, t('signatureQuarter'))}
      dialogTitle={t('signatureQuarter')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ signatureQuarter_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealQuarters}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const FilterCommissionQuarter = () => {
  const [t] = useTranslation();
  const [params, setParams] = useDealsLeadsParams();
  const selectedIds = new Set(params.commissionQuarter_in);
  const toggle = id =>
    setParams({
      commissionQuarter_in: toggleFilterItem(selectedIds, id),
    });
  const dealQuarters = GetFilterQuarterOptions();
  return (
    <Filter
      label={getLabel(dealQuarters, selectedIds, t('commisionQuarter'))}
      dialogTitle={t('commisionQuarter')}
      empty={selectedIds.size === 0}
      onReset={() => {
        setParams({ commissionQuarter_in: null });
      }}
    >
      <NormalFilterContent
        nodes={dealQuarters}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

export const DealsFilters = () => (
  <div
    css={`
      flex-shrink: 0;
      padding: 24px 1px 24px 40px;
    `}
  >
    <Flex>
      <Box p={1}>
        <FilterRegions />
      </Box>

      <Box p={1}>
        <FilterConfidence />
      </Box>
      <Box p={1}>
        <FilterUseType />
      </Box>
      <Box p={1}>
        <FilterStatus />
      </Box>
      <Box p={1}>
        <FilterPipelineStages />
      </Box>
      <Box p={1}>
        <FilterExpectedPrice />
      </Box>
      <Box p={1}>
        <FilterCommission />
      </Box>
    </Flex>
  </div>
);
