// @flow

import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import { Router, View, NotFoundBoundary } from 'react-navi';
import { graphql, useQuery } from 'relay-hooks';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box } from '@material-ui/core';

import AppThemeProvider, { frontTheme } from '../AppThemeProvider';
import { FrontendLayout } from '../components/frontend-layout';
import { lazy, mount, createBrowserNavigation } from 'navi';
import homeRoute from './routes/home';
import profileRoute from './routes/profile';
import { detectLanguage } from '../i18n';
import { SnackbarProvider } from 'notistack';

const routes = mount({
  '/': homeRoute,
  '/profile': profileRoute,
  '/deal/:id': lazy(() =>
    import(/* webpackChunkName: 'deals' */ '../front/routes/deal-details'),
  ),
});

export const FrontApp = hot(({ basename }) => {
  const { props } = useQuery({
    query: graphql`
      query frontAppQuery {
        viewer {
          id
          fullName
        }
      }
    `,
  });
  const { i18n } = useTranslation();
  React.useEffect(() => {
    i18n.changeLanguage(detectLanguage(props?.viewer));
  }, [i18n, props?.viewer]);

  const navigation = React.useMemo(() => {
    const nI = createBrowserNavigation({
      routes,
      basename,
    });

    nI._realNavigate = nI.navigate;

    // monkey-patch to handle language-based basename
    nI.navigate = function(url, options) {
      if (!url.pathname.startsWith(this.basename)) {
        url.pathname = `${this.basename ?? ''}${url.pathname.replace('/', '')}`;
        url.href = `${this.basename ?? ''}${url.href.replace('/', '')}`;
      }

      return this._realNavigate(url, options);
    };

    return nI;
  }, [basename]);

  return (
    <AppThemeProvider theme={frontTheme}>
      <SnackbarProvider>
        <Router
          navigation={navigation}
          routes={routes}
          basename={basename}
          context={{
            viewer: props?.viewer,
            basename,
          }}
        >
          <Suspense fallback={null}>
            <FrontendLayout>
              <NotFoundBoundary render={renderNotFound}>
                <View />
              </NotFoundBoundary>
            </FrontendLayout>
          </Suspense>
        </Router>
      </SnackbarProvider>
    </AppThemeProvider>
  );
});

const renderNotFound = () => (
  <Box mt={8}>
    <Container maxWidth="lg">
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Page not found</Typography>
    </Container>
  </Box>
);
