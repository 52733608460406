// @flow

import * as React from 'react';
import MuiDownshift from 'mui-downshift';
import { useQuery, graphql } from 'relay-hooks';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  useTheme,
} from '@material-ui/core';

import UserAvatar from '../components/UserAvatar';
import type { userInput_user } from './__generated__/userInput_user.graphql.js';
import type { UserFilters } from './__generated__/userInputQuery.graphql.js';

const fragment = graphql`
  fragment userInput_user on User {
    id
    fullName
    email
    jobTitle
    isBroker
    isAdmin
    organisationId
    mobilePhone
    officePhone
    ...UserAvatar_user
  }
`;

const query = graphql`
  query userInputQuery($search: String, $filters: UserFilters, $dealBrokerFilters: UserFilters) {
    users(search: $search, filters: $filters, first: 100, asRealUser: true) {
      edges {
        node {
          ...userInput_user @relay(mask: false)
        }
      }
    }
    dealBrokers: users(search: $search, filters: $dealBrokerFilters, first: 100, asRealUser: false) {
      edges {
        node {
          ...userInput_user @relay(mask: false)
        }
      }
    }
  }
`;

const UserItem = ({ user, getItemProps }) => {
  const theme = useTheme();
  return (
    <ListItem button {...getItemProps()}>
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {user.fullName}
            {user.jobTitle != null && user.jobTitle !== '' && (
              <span
                css={`
                  color: ${theme.palette.text.secondary};
                `}
              >
                {' '}
                — {user.jobTitle}
              </span>
            )}
          </>
        }
        secondary={user.email}
      />
    </ListItem>
  );
};

export type User = $ReadOnly<$Diff<userInput_user, { $refType: * }>>;

type Props = {|
  value: ?User,
  onChange: (value: ?User) => void,
  onBlur?: any => void,
  label?: string,
  variant?: 'filled' | 'outlined',
  inputProps?: any,
  filters?: UserFilters,
  loading?: boolean,
  isDealBrokers?: boolean,
  createdUserId?: string,
|};

export const UserInput = ({
  onChange,
  onBlur,
  label,
  variant,
  inputProps = {},
  filters = {},
  value,
  createdUserId,
  isDealBrokers,
}: Props) => {
  const [search, setSearch] = React.useState(value?.fullName ?? '');
  const { props } = useQuery({
    query,
    variables: {
      // FIXME: fix search with future relay upgrade
      search,
      filters,
      dealBrokerFilters: {
        dealBrokersCreatedUserId: createdUserId,
        isDealBrokers,
      },
    },
  });
  const loading = props == null || props.loading === true;
  let users;
  if (isDealBrokers) {
    users = (props?.dealBrokers?.edges ?? []).map(edge => edge.node);
  }
  else {
    users = (props?.users?.edges ?? []).map(edge => edge.node);
  }

  return (
    <MuiDownshift
      variant={variant}
      items={users}
      loading={loading}
      selectedItem={value ?? null}
      onChange={selectedItem => {
        onChange(selectedItem ?? null);
      }}
      onBlur={onBlur}
      inputValue={search}
      onInputValueChange={inputValue => {
        setSearch(inputValue);
      }}
      itemToString={item => item?.fullName ?? null}
      getInputProps={() => ({ label, ...inputProps })}
      getListItem={({ getItemProps, item }) =>
        item ? (
          <UserItem user={item} getItemProps={getItemProps} />
        ) : loading ? (
          <ListItem button disabled>
            <ListItemText
              primary={<span style={{ fontStyle: 'italic' }}>Loading...</span>}
            />
          </ListItem>
        ) : (
          <ListItem button disabled>
            <ListItemText
              primary={
                <span style={{ fontStyle: 'italic' }}>No items found</span>
              }
            />
          </ListItem>
        )
      }
    />
  );
};
