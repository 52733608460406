/**
 * @flow
 * @relayHash 05ed921149ab8be56153d2a41fdf2b9c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CURRENCY_TYPE_ENUM = "CHF" | "EUR" | "%future added value";
export type DEAL_PRICE_TYPE_ENUM = "fixed" | "on_request" | "range" | "%future added value";
export type DEAL_TYPE_ENUM = "asset" | "share" | "%future added value";
export type dealsListQueryVariables = {||};
export type dealsListQueryResponse = {|
  +viewer: ?{|
    +id: string
  |},
  +deals: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +description: ?string,
        +closingDate: ?any,
        +image: ?{|
          +url: string
        |},
        +status: ?{|
          +label: ?string,
          +group: ?string,
        |},
        +year: ?number,
        +dealType: ?{|
          +id: string,
          +label: ?string,
        |},
        +useType: ?{|
          +id: string,
          +label: ?string,
        |},
        +regions: $ReadOnlyArray<?{|
          +id: string,
          +label: ?string,
        |}>,
        +dealProgressStage: ?{|
          +id: string,
          +label: ?string,
        |},
        +constructionPeriod: ?string,
        +expectedRent: ?number,
        +vacancyRate: ?number,
        +location: ?string,
        +type: ?DEAL_TYPE_ENUM,
        +price: ?number,
        +priceMin: ?number,
        +priceMax: ?number,
        +currencyType: ?CURRENCY_TYPE_ENUM,
        +priceType: ?DEAL_PRICE_TYPE_ENUM,
      |}
    |}>
  |},
|};
export type dealsListQuery = {|
  variables: dealsListQueryVariables,
  response: dealsListQueryResponse,
|};
*/


/*
query dealsListQuery {
  viewer {
    id
  }
  deals(sortDirection: DESC) {
    edges {
      node {
        id
        title
        description
        closingDate
        image {
          url
          id
        }
        status {
          label
          group
          id
        }
        year
        dealType {
          id
          label
        }
        useType {
          id
          label
        }
        regions {
          id
          label
        }
        dealProgressStage {
          id
          label
        }
        constructionPeriod
        expectedRent
        vacancyRate
        location
        type
        price
        priceMin
        priceMax
        currencyType
        priceType
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "viewer",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
},
v2 = [
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "DESC"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "closingDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
},
v10 = [
  (v0/*: any*/),
  (v7/*: any*/)
],
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dealType",
  "storageKey": null,
  "args": null,
  "concreteType": "DealType",
  "plural": false,
  "selections": (v10/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "useType",
  "storageKey": null,
  "args": null,
  "concreteType": "UseType",
  "plural": false,
  "selections": (v10/*: any*/)
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "regions",
  "storageKey": null,
  "args": null,
  "concreteType": "Region",
  "plural": true,
  "selections": (v10/*: any*/)
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dealProgressStage",
  "storageKey": null,
  "args": null,
  "concreteType": "DealProgressStage",
  "plural": false,
  "selections": (v10/*: any*/)
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "constructionPeriod",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expectedRent",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vacancyRate",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "location",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceMin",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceMax",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currencyType",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "priceType",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dealsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deals",
        "storageKey": "deals(sortDirection:\"DESC\")",
        "args": (v2/*: any*/),
        "concreteType": "DealConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DealEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Deal",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DealStatus",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "dealsListQuery",
    "argumentDefinitions": [],
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deals",
        "storageKey": "deals(sortDirection:\"DESC\")",
        "args": (v2/*: any*/),
        "concreteType": "DealConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DealEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Deal",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DealStatus",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v0/*: any*/)
                    ]
                  },
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "dealsListQuery",
    "id": null,
    "text": "query dealsListQuery {\n  viewer {\n    id\n  }\n  deals(sortDirection: DESC) {\n    edges {\n      node {\n        id\n        title\n        description\n        closingDate\n        image {\n          url\n          id\n        }\n        status {\n          label\n          group\n          id\n        }\n        year\n        dealType {\n          id\n          label\n        }\n        useType {\n          id\n          label\n        }\n        regions {\n          id\n          label\n        }\n        dealProgressStage {\n          id\n          label\n        }\n        constructionPeriod\n        expectedRent\n        vacancyRate\n        location\n        type\n        price\n        priceMin\n        priceMax\n        currencyType\n        priceType\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '34c6ac1d976dac889dbfe9f81c3bcee6';
module.exports = node;
