/* @ flow */
import * as React from 'react';
import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';
import type { HOC } from 'recompose';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
/* $ExpectError */
import { Link, withRouter } from 'react-router-dom';
import { Avatar, colors, Divider, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withTranslation as translate } from 'react-i18next';
import { ClassNames } from '@emotion/core';

import { SearchStandalone, SearchToolbar } from './SearchControl';
import { Highlighter, normalizeSearchString } from '../controls/Highlighter';
import UserAvatar from '../../../components/UserAvatar/UserAvatar';
import { getParentData } from '../Activities/ActivitiesCards/ActivityCard';

type RowTypes =
  | {
      key: 'users',
      data: {
        id: string,
        firstName: string,
        lastName: string,
        email: string,
      },
    }
  | {
      key: 'organisations',
      data: {
        id: string,
        name: string,
        street: string,
        city: string,
      },
    }
  | {
      key: 'activities',
      data: {
        parent: {
          id: string,
          __typename: string,
        },
        note: string,
        subject: string,
        user: {
          firstName: string,
          lastName: string,
        },
        organisation: {
          name: string,
        },
      },
    }
  | {
      key: 'deals',
      data: {
        id: string,
        name: string,
      },
    };

const titles = {
  users: 'Users',
  organisations: 'Organisations',
  activities: 'Activities',
  deals: 'Deals',
  showAll: 'showAll',
};

const titleBgs = {
  users: '#1e88e5',
  organisations: '#00897b',
  activities: '#546e7a',
  deals: '#8e24aa',
};

const HeaderLabel = styled.span`
  color: ${props => props['data-color']};
  display: inline-block;
`;

const UListSubheader = styled(ListSubheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;

const renderHeader = ({ key, totalCount, showCount, isFirst }) => (
  <div style={{ flex: 1, background: '#fff' }}>
    {!isFirst && <Divider style={{ marginTop: 6 }} />}
    <UListSubheader>
      <HeaderLabel data-color={titleBgs[key]}>{titles[key]}</HeaderLabel>
      <span>{totalCount ? `${showCount} of ${totalCount}` : 'Not found'}</span>
    </UListSubheader>
  </div>
);

const getLink = (rowData: RowTypes, inputValue) => {
  if (rowData.key === 'showMore') {
    return `/search/${encodeURIComponent(inputValue)}`;
  }

  if (rowData.key === 'users') {
    return `/individuals/${rowData.data.id}`;
  }

  if (rowData.key === 'organisations') {
    return `/organisations/${rowData.data.id}`;
  }

  if (rowData.key === 'activities' && rowData.data.parent) {
    return `${
      rowData.data.parent.__typename === 'Lead'
        ? `/deals/${rowData.data.parent.deal.id}/longlist/${rowData.data.parent.id}`
        : `${
            {
              User: '/individuals',
              Organisation: '/organisations',
              Deal: '/deals',
            }[rowData.data.parent.__typename]
          }/${rowData.data.parent.id}`
    }#activity-${rowData.data.id}`;
  }

  if (rowData.key === 'deals') {
    return `/deals/${rowData.data.id}`;
  }

  return '';
};

const UListItem = styled(ListItem)`
  background-color: ${props =>
    props['data-active'] ? props.theme.palette.action.hover : '#fff'};
  flex: 1;
  h3,
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const UUserAvatar = styled(UserAvatar)`
  height: 36px;
  width: 36px;
  font-size: 16px;
`;

const ShowMoreText = styled(Typography).attrs({ color: 'textSecondary' })`
  font-size: inherit;
`;

const searchMatch = (str, search) =>
  str && search
    ? normalizeSearchString(str)
        .toLocaleUpperCase()
        .includes(normalizeSearchString(search).toLocaleUpperCase())
    : false;

const TitleMatched = ({ search, title, label }) => {
  if (!searchMatch(title, search)) {
    return null;
  }

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        margin: 4px 0;
      `}
    >
      <div
        css={`
          font-family: monospace;
          background-color: ${colors.grey[100]};
          margin-right: 8px;
          padding: 2px 4px;
          border-radius: 4px;
          font-weight: 500;
        `}
      >
        {label}
      </div>
      <Highlighter partial search={search} text={title} />
    </div>
  );
};

const renderRow = (rowData: RowTypes, inputValue: string, focused) => (
  <UListItem
    button
    component={Link}
    to={getLink(rowData, inputValue)}
    data-active={focused}
  >
    {rowData.key === 'showMore' && (
      <>
        <ListItemText
          inset
          primary={
            <ShowMoreText>
              ... show {rowData.totalCount - rowData.showCount} more
            </ShowMoreText>
          }
        />
        <OpenInNewIcon color="action" />
      </>
    )}
    {rowData.key === 'users' && (
      <>
        <ListItemText
          inset
          primary={
            <Highlighter
              text={[rowData.data.firstName, rowData.data.lastName]
                .filter(v => !!v)
                .join(' ')}
              search={inputValue}
            />
          }
        />
        {!!rowData.data.primaryImage && <UUserAvatar user={rowData.data} />}
      </>
    )}
    {rowData.key === 'organisations' && (
      <ClassNames>
        {({ css }) => (
          <>
            <ListItemText
              inset
              primary={
                <Highlighter
                  partial
                  search={inputValue}
                  text={rowData.data.name}
                  css={`
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  `}
                />
              }
              primaryTypographyProps={{
                noWrap: true,
                className: css`
                  display: flex;
                `,
              }}
              secondary={
                <>
                  <Highlighter
                    partial
                    search={inputValue}
                    text={rowData.data.street}
                  />
                  {rowData.data.street && ', '}
                  <span>{rowData.data.city}</span>
                </>
              }
              secondaryTypographyProps={{ noWrap: true }}
            />
            {!!rowData.data.primaryImage && (
              <UUserAvatar organisation={rowData.data} />
            )}
          </>
        )}
      </ClassNames>
    )}
    {rowData.key === 'deals' && (
      <>
        <ListItemText
          inset={true}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          primary={
            <Highlighter
              search={inputValue}
              text={rowData.data.title || rowData.data.name}
            />
          }
          secondary={
            <>
              <TitleMatched
                label="en"
                title={rowData.data.titleEn}
                search={inputValue}
              />
              <TitleMatched
                label="fr"
                title={rowData.data.titleFr}
                search={inputValue}
              />
              <TitleMatched
                label="de"
                title={rowData.data.titleDe}
                search={inputValue}
              />
            </>
          }
        />
        {!!rowData.data.primaryImage && (
          <Avatar src={rowData.data.primaryImage} />
        )}
      </>
    )}
    {rowData.key === 'activities' && (
      <>
        <ListItemText
          inset
          primary={getParentData(rowData.data.parent).primary}
          primaryTypographyProps={{ noWrap: true }}
          secondary={
            <Highlighter
              partial
              search={inputValue}
              text={rowData.data.note || rowData.data.subject}
            />
          }
          secondaryTypographyProps={{ noWrap: true }}
        />
        <Typography variant="caption">
          {getParentData(rowData.data.parent).secondary}
        </Typography>
      </>
    )}
  </UListItem>
);

const emptyFn = () => {};

const globalSearchView = ({
  inputValue,
  onInputChange,
  value,
  keys,
  handleRowClick,
  isPage,
  SearchComponentControl,
  t,
}) => (
  <SearchComponentControl
    disableUnderline={!isPage}
    open={isPage || undefined}
    onOpen={isPage ? emptyFn : undefined}
    onClose={isPage ? emptyFn : undefined}
    label={isPage ? 'Search' : undefined}
    placeholder={t('search.placeholder')}
    onInputChange={onInputChange}
    inputValue={inputValue}
    keys={keys}
    value={value}
    renderHeader={renderHeader}
    renderRow={renderRow}
    onRowClick={handleRowClick}
  />
);

const enhacer: HOC<
  *,
  {
    isPage: boolean,
    inputValue: string,
    onInputChange: (str: string) => void,
    value: ?{
      [id: string]: ?{
        results: Array<{}>,
        totalCount: number,
      },
    },
  },
> = compose(
  translate('admin'),
  withStateHandlers(
    {
      keys: [
        {
          id: 'users',
          enabled: true,
          rowHeight: () => 48,
        },
        { id: 'organisations', enabled: true, rowHeight: () => 64 },
        {
          id: 'deals',
          enabled: true,
          rowHeight: ({ data, inputValue }) => {
            let height = 48;
            const titleHeight = 24;

            if (searchMatch(data.titleEn, inputValue)) {
              height += titleHeight;
            }
            if (searchMatch(data.titleFr, inputValue)) {
              height += titleHeight;
            }
            if (searchMatch(data.titleDe, inputValue)) {
              height += titleHeight;
            }

            return height;
          },
        },
        { id: 'activities', enabled: true, rowHeight: () => 64 },
        { id: 'showMore', enabled: true, rowHeight: () => 48 },
      ],
    },
    {},
  ),
  withRouter,
  withHandlers({
    handleRowClick: ({ history, inputValue }) => (rowData: RowTypes) => {
      const link = getLink(rowData, inputValue);
      history.push(link);
    },
  }),
  withProps(({ isPage }) => ({
    SearchComponentControl: isPage ? SearchStandalone : SearchToolbar,
  })),
);

export default enhacer(globalSearchView);
