/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { AppBar, Box, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Route, Switch } from 'react-router-dom';
import { GatewayDest } from 'react-gateway';
import SearchIcon from '@material-ui/icons/Search';
import withWidth from '@material-ui/core/withWidth';

import GlobalSearch from '../../routes/admin/GlobalSearch';
import { uniMaterial } from '../../routes/admin/controls/UniStyled';
import { TitleRenderer } from '../../routes/admin/controls/Title';
import { UserMenu } from '../user-menu';

const TopBar = styled(AppBar)`
  right: 0;
  width: unset;
  transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  left: ${p => (p['data-open'] ? p['data-max-w'] : 0)}px;
  max-width: 100vw;
`;

const AnimIconButton = styled(IconButton)`
  overflow: hidden;
  transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  width: ${p => (p['data-open'] ? 0 : 48)}px;
`;

const StToolBar = uniMaterial(Toolbar);

const adminAppBar = ({
  open,
  onMenuClick,
  navMenuMinWidth,
  navMenuMaxWidth,
  width,
  viewer,
}) => {
  return (
    <TopBar
      data-open={open}
      data-min-w={navMenuMinWidth}
      data-max-w={navMenuMaxWidth}
      position="fixed"
      color="primary"
    >
      <StToolBar data-padding-left="8px" data-justify-content="space-between">
        <Box display="flex" flex={1} alignItems="center" mr={5} minWidth={0}>
          <Box>
            <AnimIconButton
              data-open={open}
              aria-label="Menu"
              onClick={onMenuClick}
              color="inherit"
            >
              <MenuIcon />
            </AnimIconButton>
          </Box>
          <TitleRenderer />
        </Box>
        <Switch>
          <Route path="/search/:search" component={() => null} />
          <Route render={() => width !== 'xs' && <GlobalSearch />} />
        </Switch>
        <Box display="flex">
          <GatewayDest name="actions" />
          {width === 'xs' && (
            <Link to="/search">
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
            </Link>
          )}
          {viewer != null && (
            <Box ml={2}>
              <UserMenu
                viewer={viewer}
                refetch={() => window.location.reload()}
              />
            </Box>
          )}
        </Box>
      </StToolBar>
    </TopBar>
  );
};

export default compose(withWidth())(adminAppBar);
