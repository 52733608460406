/**
 * @flow
 * @relayHash 1a1abbec5d96dea0fcf6d322f2242187
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DealsList_root$ref = any;
export type DEAL_CONFIDENCE_TYPE_ENUM = "high" | "low" | "medium" | "%future added value";
export type SORT_DIRECTION = "ASC" | "DESC" | "%future added value";
export type DealFilters = {|
  brokerId_in?: ?$ReadOnlyArray<string>,
  sellerId_in?: ?$ReadOnlyArray<string>,
  dealStatusId_in?: ?$ReadOnlyArray<string>,
  confidence_in?: ?$ReadOnlyArray<DEAL_CONFIDENCE_TYPE_ENUM>,
  dealPipelineStageId_in?: ?$ReadOnlyArray<string>,
  regionId_in?: ?$ReadOnlyArray<string>,
  useTypeId_in?: ?$ReadOnlyArray<string>,
  expectedSalePrice_gte?: ?number,
  expectedSalePrice_lte?: ?number,
  potentialCommission_gte?: ?number,
  potentialCommission_lte?: ?number,
  signatureQuarter_in?: ?$ReadOnlyArray<string>,
  commissionQuarter_in?: ?$ReadOnlyArray<string>,
|};
export type DealsQueryVariables = {|
  count: number,
  cursor?: ?string,
  sortBy?: ?string,
  sortDirection?: ?SORT_DIRECTION,
  filters?: ?DealFilters,
|};
export type DealsQueryResponse = {|
  +$fragmentRefs: DealsList_root$ref
|};
export type DealsQuery = {|
  variables: DealsQueryVariables,
  response: DealsQueryResponse,
|};
*/


/*
query DealsQuery(
  $count: Int!
  $cursor: String
  $sortBy: String
  $sortDirection: SORT_DIRECTION
  $filters: DealFilters
) {
  ...DealsList_root
}

fragment DealsList_root on Query {
  deals(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {
    edges {
      node {
        id
        name
        title
        year
        expectedRent
        vacancyRate
        expectedSalePrice
        signatureQuarter
        commissionQuarter
        dealFinalPrice
        effectiveSalePrice
        courtierCommisionHt
        aiCommisionHt
        insuranceValue
        cashReceptionDate
        signatureDate
        closingDate
        createdAt
        regions {
          id
          label
        }
        image {
          url
          id
        }
        useTypes {
          id
          label
        }
        status {
          id
          label
          group
        }
        dealPipelineStage {
          id
          label
        }
        confidence
        commissionCalculated
        buyersInvited
        seller {
          id
          name
          ...UserAvatar_organisation
        }
        owner {
          id
          fullName
          ...UserAvatar_user
        }
        __typename
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
}

fragment UserAvatar_organisation on Organisation {
  id
  name
  primaryImage {
    url
    id
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortDirection",
    "type": "SORT_DIRECTION",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "DealFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": (v6/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DealsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DealsList_root",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DealsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deals",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DealConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DealEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Deal",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "year",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "expectedRent",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "vacancyRate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "expectedSalePrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "signatureQuarter",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "commissionQuarter",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dealFinalPrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "effectiveSalePrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "courtierCommisionHt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "aiCommisionHt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "insuranceValue",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cashReceptionDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "signatureDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "closingDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "regions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Region",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Image",
                    "plural": false,
                    "selections": (v6/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "useTypes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UseType",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "status",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DealStatus",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "group",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dealPipelineStage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DealPipelineStage",
                    "plural": false,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "confidence",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "commissionCalculated",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "buyersInvited",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "seller",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "owner",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "deals",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "Connection_deals",
        "filters": [
          "sortBy",
          "sortDirection",
          "filters"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DealsQuery",
    "id": null,
    "text": "query DealsQuery(\n  $count: Int!\n  $cursor: String\n  $sortBy: String\n  $sortDirection: SORT_DIRECTION\n  $filters: DealFilters\n) {\n  ...DealsList_root\n}\n\nfragment DealsList_root on Query {\n  deals(first: $count, after: $cursor, sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters) {\n    edges {\n      node {\n        id\n        name\n        title\n        year\n        expectedRent\n        vacancyRate\n        expectedSalePrice\n        signatureQuarter\n        commissionQuarter\n        dealFinalPrice\n        effectiveSalePrice\n        courtierCommisionHt\n        aiCommisionHt\n        insuranceValue\n        cashReceptionDate\n        signatureDate\n        closingDate\n        createdAt\n        regions {\n          id\n          label\n        }\n        image {\n          url\n          id\n        }\n        useTypes {\n          id\n          label\n        }\n        status {\n          id\n          label\n          group\n        }\n        dealPipelineStage {\n          id\n          label\n        }\n        confidence\n        commissionCalculated\n        buyersInvited\n        seller {\n          id\n          name\n          ...UserAvatar_organisation\n        }\n        owner {\n          id\n          fullName\n          ...UserAvatar_user\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    totalCount\n  }\n}\n\nfragment UserAvatar_organisation on Organisation {\n  id\n  name\n  primaryImage {\n    url\n    id\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79a7f39bb5459577085e470a3d65bf16';
module.exports = node;
