/**
 * @flow
 * @relayHash e7b561e981c0bc2b3bd39addd82f7a1d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ACTIVITY_TYPE = "DEAL_INTEREST" | "DEAL_INVITED" | "DEAL_VIEW" | "DOCUMENT_VIEW" | "EMAIL" | "LONG_LIST_EDIT" | "NOTE" | "TASK" | "USER_DELETED" | "%future added value";
export type UpdateActivityInput = {|
  id: string,
  assigneeId?: ?string,
  note?: ?string,
  subject?: ?string,
  isSuccess?: ?boolean,
  isDone?: ?boolean,
  dueAt?: ?any,
  clientMutationId?: ?string,
|};
export type activitiesUpdateMutationVariables = {|
  input: UpdateActivityInput
|};
export type activitiesUpdateMutationResponse = {|
  +updateActivity: ?{|
    +activity: ?{|
      +id: string,
      +type: ACTIVITY_TYPE,
      +createdAt: ?any,
      +updatedAt: ?any,
      +dueAt: ?any,
      +doneAt: ?any,
      +parent: ?{|
        +id: string,
        +__typename: string,
      |},
      +note: ?string,
      +subject: ?string,
      +isDone: ?boolean,
      +isSuccess: ?boolean,
      +assignee: ?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
      |},
      +creator: ?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
      |},
    |}
  |}
|};
export type activitiesUpdateMutation = {|
  variables: activitiesUpdateMutationVariables,
  response: activitiesUpdateMutationResponse,
|};
*/


/*
mutation activitiesUpdateMutation(
  $input: UpdateActivityInput!
) {
  updateActivity(input: $input) {
    activity {
      id
      type
      createdAt
      updatedAt
      dueAt
      doneAt
      parent {
        id
        __typename
      }
      note
      subject
      isDone
      isSuccess
      assignee {
        id
        firstName
        lastName
      }
      creator {
        id
        firstName
        lastName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateActivityInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateActivityPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activity",
        "storageKey": null,
        "args": null,
        "concreteType": "Activity",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dueAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "doneAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "parent",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "note",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subject",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isDone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSuccess",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignee",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "activitiesUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "activitiesUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "activitiesUpdateMutation",
    "id": null,
    "text": "mutation activitiesUpdateMutation(\n  $input: UpdateActivityInput!\n) {\n  updateActivity(input: $input) {\n    activity {\n      id\n      type\n      createdAt\n      updatedAt\n      dueAt\n      doneAt\n      parent {\n        id\n        __typename\n      }\n      note\n      subject\n      isDone\n      isSuccess\n      assignee {\n        id\n        firstName\n        lastName\n      }\n      creator {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c1196527ed8ba6e48f412c1e1115e3a';
module.exports = node;
