import React from 'react';
import { withHandlers, withState, compose, pure } from 'recompose';
import { Menu, IconButton, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const Root = styled.div`
  display: inline-block;
`;

const ButtonMenu = ({
  children,
  icon,
  handleClick,
  anchorEl,
  handleClose,
  title,
}) => {
  const button = (
    <IconButton onClick={handleClick}>{icon || <MoreVertIcon />}</IconButton>
  );
  return (
    <Root>
      {title ? (
        <Tooltip title={title} enterDelay={200}>
          {button}
        </Tooltip>
      ) : (
        button
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </Menu>
    </Root>
  );
};

export default compose(
  pure,
  withState('anchorEl', 'setAnchorEl', null),
  withHandlers({
    handleClose: ({ setAnchorEl }) => event => {
      event.preventDefault();
      setAnchorEl(null);
    },
    handleClick: ({ setAnchorEl }) => event => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
  }),
)(ButtonMenu);
