/**
 * @flow
 * @relayHash d9b574c8be293555006367307afd5dee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type USER_LANGUAGE = "de" | "en" | "fr" | "it" | "%future added value";
export type UpdateUserInput = {|
  id: string,
  organisationId?: ?string,
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  language?: ?string,
  gender?: ?string,
  jobTitle?: ?string,
  mobilePhone?: ?string,
  officePhone?: ?string,
  street?: ?string,
  streetNr?: ?string,
  postcode?: ?string,
  city?: ?string,
  country?: ?string,
  isSubscribed?: ?boolean,
  isActive?: ?boolean,
  isAdmin?: ?boolean,
  proofRead?: ?boolean,
  proofReadDate?: ?any,
  isSuperAdmin?: ?boolean,
  primaryImageId?: ?string,
  linkedinUrl?: ?string,
  ownerId?: ?string,
  isInvestor?: ?boolean,
  isSeller?: ?boolean,
  isBroker?: ?boolean,
  isSupervisor?: ?boolean,
  clientMutationId?: ?string,
|};
export type withChangeLanguageMutationVariables = {|
  input: UpdateUserInput
|};
export type withChangeLanguageMutationResponse = {|
  +updateUser: ?{|
    +user: ?{|
      +id: string,
      +language: ?USER_LANGUAGE,
    |},
    +clientMutationId: ?string,
  |}
|};
export type withChangeLanguageMutation = {|
  variables: withChangeLanguageMutationVariables,
  response: withChangeLanguageMutationResponse,
|};
*/


/*
mutation withChangeLanguageMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    user {
      id
      language
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "withChangeLanguageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "withChangeLanguageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "withChangeLanguageMutation",
    "id": null,
    "text": "mutation withChangeLanguageMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n      language\n    }\n    clientMutationId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b809d5f1d11d275541843a2538414c06';
module.exports = node;
