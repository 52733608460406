import React from 'react';
import { compose } from 'recompose';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { Link, Route, withRouter } from 'react-router-dom';
import { withTranslation as translate } from 'react-i18next';

import DictionariesTab from './Dictionaries/DictionariesTab';
import BaseSettingsTab from './BaseSettings/BaseSettingsTab';
import TitleProvider from '../controls/Title/TitleProvider';

const TabsAppBar = styled(AppBar).attrs({
  position: 'static',
  component: 'nav',
})`
  z-index: 1;
  background: #ffffff;
`;

const TABS = {
  BASE_SETTINGS: 'base',
  DICTIONARIES: 'dictionaries',
};

// TODO: make it loadable
const SettingsPage = ({ match, t }) => (
  <TitleProvider text={t('settings')}>
    <Route
      path={`${match.url}/:tab?`}
      render={({ match: { params } }) => {
        const tabValue =
          params.tab && Object.values(TABS).includes(params.tab)
            ? params.tab
            : TABS.BASE_SETTINGS;
        return (
          <React.Fragment>
            <TabsAppBar>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabValue}
              >
                <Tab
                  label={t('baseSettings')}
                  value={TABS.BASE_SETTINGS}
                  component={Link}
                  to={`${match.url}/${TABS.BASE_SETTINGS}`}
                />
                <Tab
                  label={t('dictionaries')}
                  value={TABS.DICTIONARIES}
                  component={Link}
                  to={`${match.url}/${TABS.DICTIONARIES}`}
                />
              </Tabs>
            </TabsAppBar>

            {tabValue === TABS.BASE_SETTINGS && <BaseSettingsTab />}
            {tabValue === TABS.DICTIONARIES && <DictionariesTab />}
          </React.Fragment>
        );
      }}
    />
  </TitleProvider>
);

const enhancer = compose(
  withRouter,
  translate('settings'),
);

export default enhancer(SettingsPage);
