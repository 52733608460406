import React from 'react';
import { compose, pure, getContext, withProps, withHandlers } from 'recompose';
import { PropTypes } from 'prop-types';
import idx from 'idx';
import { graphql } from 'react-relay';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { withFormik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, Checkbox, Grid } from '@material-ui/core';
import { withTranslation as translate } from 'react-i18next';

import parseErrorsLegacy from '../../routes/admin/utils/parseErrorsLegacy';
import {
  uniMaterial,
  uniMaterial2x,
} from '../../routes/admin/controls/UniStyled';
import InputNumber from '../../routes/admin/controls/InputNumber';
import {
  createFragmentContainerHOC,
  withMutation,
} from '../../utils/relay/index';
import mapKeys from '../../routes/admin/utils/mapKeys';
import hasIntersection from '../../routes/admin/utils/hasIntersection';
import Button from '../ButtonProgress';
import { isDisabledSaveOrganisation } from '../../utils/checkOrganisationPermission';

const Item = styled(uniMaterial2x(Grid)).attrs({
  // TextField error breaks height otherwise
  // 'data-height': '66px',
  item: true,
})``;

const LItem = styled(Grid).attrs({ item: true })`
  height: 40px;
`;

const Container = styled(uniMaterial(Grid)).attrs({
  container: true,
})``;

const SaveIndicator = styled(LinearProgress)`
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

const ErrorText = styled(uniMaterial(FormHelperText)).attrs({ error: true })`
  padding-left: 8px;
  padding-bottom: 8px;
  word-wrap: break-word;
`;

const LocalCheckBox = pure(
  withHandlers({
    onClick: ({ setFieldValue, setFieldTouched, id, value, values }) => () => {
      //
      setFieldValue(
        id,
        values.map(u => ({
          ...u,
          value: u.id === value.id ? !u.value : u.value,
        })),
      );
      setFieldTouched(id);
    },
  })(({ onClick, value, id, label }) => (
    <FormControlLabel
      control={<Checkbox checked={value.value} onClick={onClick} id={id} />}
      label={label}
    />
  )),
);

export const organisationDetailsInvestorProfileForm = ({
  values,
  errors,
  handleChange,
  setFieldTouched,
  handleSubmit,
  setFieldValue,
  handleReset,
  isSubmitting,
  dirty,
  touched,
  isNewOrganisation,
  router,
  t,
  organisation,
  currentUser,
  onUpdateData,
}) => (
  <form onSubmit={handleSubmit}>
    {isSubmitting && <SaveIndicator />}
    <Container spacing={3}>
      <Grid item sm={12}>
        <Typography variant="h6">{t('investorProfile')}</Typography>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Grid container spacing={2}>
          <Item xs={12} sm={12} md={6} data-height="65px">
            <InputNumber
              id="assetsUnderManagement"
              label={t('AuM')}
              type="tel"
              value={values.assetsUnderManagement || ''}
              onChange={handleChange}
              error={Boolean(
                touched.assetsUnderManagement && errors.assetsUnderManagement,
              )}
              helperText={
                touched.assetsUnderManagement && errors.assetsUnderManagement
              }
              fullWidth
            />
          </Item>

          <Item xs={6} sm={6} md={3} data-height="65px">
            <InputNumber
              id="minAssetSize"
              type="tel"
              label={t('minAssetSize')}
              value={values.minAssetSize || ''}
              onChange={handleChange}
              error={Boolean(touched.minAssetSize && errors.minAssetSize)}
              helperText={touched.minAssetSize && errors.minAssetSize}
              fullWidth
            />
          </Item>
          <Item xs={6} sm={6} md={3} data-height="65px">
            <InputNumber
              id="maxAssetSize"
              type="tel"
              label={t('maxAssetSize')}
              value={values.maxAssetSize || ''}
              onChange={handleChange}
              error={Boolean(touched.maxAssetSize && errors.maxAssetSize)}
              helperText={touched.maxAssetSize && errors.maxAssetSize}
              fullWidth
            />
          </Item>
        </Grid>
      </Grid>
      <Item sm={12} xs={12} data-margin-top="16px">
        <FormLabel component="legend">{t('useTypes')}</FormLabel>
      </Item>
      <Item sm={12} xs={12} data-margin-top="-8px">
        <Grid container spacing={2}>
          {values.useTypes.map(useType => (
            <LItem key={useType.id} xs={12} sm={6}>
              <LocalCheckBox
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                value={useType}
                values={values.useTypes}
                id="useTypes"
                label={useType.label}
              />
            </LItem>
          ))}
        </Grid>
      </Item>

      <Item sm={12} xs={12} data-margin-top="16px">
        <FormLabel component="legend">{t('regions')}</FormLabel>
      </Item>
      <Item sm={12} xs={12} data-margin-top="-8px">
        <Grid container spacing={2}>
          {values.regions.map(region => (
            <LItem key={region.id} xs={12} sm={6}>
              <LocalCheckBox
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                value={region}
                values={values.regions}
                id="regions"
                label={region.label}
              />
            </LItem>
          ))}
        </Grid>
      </Item>

      <Item sm={12} xs={12} data-margin-top="16px">
        <FormLabel component="legend">{t('other')}</FormLabel>
      </Item>
      <Item sm={12} xs={12} data-margin-top="-10px">
        <Grid container spacing={2}>
          <Item xs={12} sm={6} data-height="40px">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.lexKollerCompliant}
                  onClick={() => {
                    setFieldValue(
                      'lexKollerCompliant',
                      !values.lexKollerCompliant,
                    );
                    setFieldTouched('lexKollerCompliant');
                  }}
                  id="lexKollerCompliant"
                />
              }
              label={t('lexKollerCompliant')}
            />
          </Item>
          <Item xs={12} sm={6} data-height="40px">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.onlyIndirect}
                  onClick={() => {
                    setFieldValue('onlyIndirect', !values.onlyIndirect);
                    setFieldTouched('onlyIndirect');
                  }}
                  id="onlyIndirect"
                />
              }
              label={t('onlyIndirectInvestments')}
            />
          </Item>
        </Grid>
      </Item>

      <Item sm={12} xs={12} data-margin-top="16px">
        <FormLabel component="legend">{t('strategies')}</FormLabel>
      </Item>
      <Item sm={12} xs={12} data-margin-top="-10px">
        <Grid container spacing={2}>
          <LItem xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.core}
                  onClick={() => {
                    setFieldValue('core', !values.core);
                    setFieldTouched('core');
                  }}
                  id="core"
                />
              }
              label={t('strategy.core')}
            />
          </LItem>
          <LItem xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.corePlus}
                  onClick={() => {
                    setFieldValue('corePlus', !values.corePlus);
                    setFieldTouched('corePlus');
                  }}
                  id="corePlus"
                />
              }
              label={t('strategy.corePlus')}
            />
          </LItem>
          <LItem xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.valueAdd}
                  onClick={() => {
                    setFieldValue('valueAdd', !values.valueAdd);
                    setFieldTouched('valueAdd');
                  }}
                  id="valueAdd"
                />
              }
              label={t('strategy.valueAdd')}
            />
          </LItem>
          <LItem xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.opportunistic}
                  onClick={() => {
                    setFieldValue('opportunistic', !values.opportunistic);
                    setFieldTouched('opportunistic');
                  }}
                  id="opportunistic"
                />
              }
              label={t('strategy.opportunistic')}
            />
          </LItem>
        </Grid>
      </Item>

      <Item sm={12} xs={12} data-margin-top="16px">
        <FormLabel component="legend">{t('purchaseTypes')}</FormLabel>
      </Item>
      <Item sm={12} xs={12} data-margin-top="-8px">
        <Grid container spacing={2}>
          {values.purchaseTypes?.map(purchaseType => (
            <LItem key={purchaseType.id} xs={12} sm={6}>
              <LocalCheckBox
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                value={purchaseType}
                values={values.purchaseTypes}
                id="purchaseTypes"
                label={purchaseType.label}
              />
            </LItem>
          ))}
        </Grid>
      </Item>
      {/*  Router is available only on /admin  */}
      {!!router && (
        <Prompt
          when={!(!dirty || hasIntersection(errors, touched))}
          message={t('detailsNotSaved')}
        />
      )}

      <Grid item sm={12} xs={12}>
        <Container
          data-padding-right="16px"
          spacing={3}
          justify="flex-end"
          alignItems="center"
        >
          {(errors.$others || hasIntersection(errors, touched)) && (
            <Grid item sm={isNewOrganisation ? 12 : 8}>
              <ErrorText data-text-align="right">
                {errors.$others || t('formHasErrors')}
              </ErrorText>
            </Grid>
          )}
          <Grid item sm={isNewOrganisation ? 12 : 4}>
            <Container
              data-height="70px"
              justify="flex-end"
              alignItems="center"
              data-flex-wrap="nowrap"
            >
              <Button onClick={handleReset} disabled={!dirty || isSubmitting || isDisabledSaveOrganisation(isNewOrganisation, currentUser, organisation)}>
                {t('button.cancel')}
              </Button>
              <Button
                color={hasIntersection(errors, touched) ? 'accent' : 'primary'}
                type="submit"
                disabled={
                  !dirty || isSubmitting || hasIntersection(errors, touched) || isDisabledSaveOrganisation(isNewOrganisation, currentUser, organisation)
                }
                loading={isSubmitting}
              >
                {t('button.submit')}
              </Button>
            </Container>
          </Grid>
        </Container>
      </Grid>
    </Container>
  </form>
);

const fragments = {
  organisation: graphql`
    fragment OrganisationInvestorProfileForm_organisation on Organisation {
      id
      minAssetSize
      maxAssetSize
      assetsUnderManagement
      lexKollerCompliant
      useTypeIds
      regionIds
      purchaseTypeIds
      core
      corePlus
      valueAdd
      opportunistic
      onlyIndirect
      owner {
        id
        fullName
        isBroker
        organisationId
      }
    }
  `,
  useTypes: graphql`
    fragment OrganisationInvestorProfileForm_useTypes on UseType
      @relay(plural: true) {
      id
      label
    }
  `,
  regions: graphql`
    fragment OrganisationInvestorProfileForm_regions on Region
      @relay(plural: true) {
      id
      label
    }
  `,
  purchaseTypes: graphql`
    fragment OrganisationInvestorProfileForm_purchaseTypes on PurchaseType
      @relay(plural: true) {
      id
      label
    }
  `,
};

export const organisationDetailsInvestorProfileFormHOC = compose(
  translate('organisations'),
  createFragmentContainerHOC(fragments),
  getContext({
    api: PropTypes.shape({
      commitMutation: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.object,
  }),
  withMutation(
    graphql`
      mutation OrganisationInvestorProfileFormUpdateOrganisationMutation(
        $input: UpdateOrganisationInput!
      ) {
        updateOrganisation(input: $input) {
          organisation {
            id
            ...OrganisationInvestorProfileForm_organisation
          }
        }
      }
    `,
    'updateOrganisation',
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({
      organisation: {
        core,
        corePlus,
        valueAdd,
        opportunistic,
        ...organisation
      },
      useTypes,
      regions,
      purchaseTypes,
    }) => ({
      minAssetSize: organisation.minAssetSize,
      maxAssetSize: organisation.maxAssetSize,
      lexKollerCompliant: !!organisation.lexKollerCompliant,
      onlyIndirect: !!organisation.onlyIndirect,
      assetsUnderManagement: organisation.assetsUnderManagement,
      core,
      corePlus,
      valueAdd,
      opportunistic,
      useTypes: useTypes.map(({ id, label }) => ({
        id,
        label,
        value: organisation.useTypeIds.indexOf(id) > -1,
      })),
      regions: regions.map(({ id, label }) => ({
        id,
        label,
        value: organisation.regionIds.indexOf(id) > -1,
      })),
      purchaseTypes: purchaseTypes ? purchaseTypes.map(({ id, label }) => ({
        id,
        label,
        value: organisation.purchaseTypeIds.indexOf(id) > -1,
      })) : [],
    }),
    validate: () => {},
    handleSubmit: async (
      values,
      {
        props: { organisation, updateOrganisation, onUpdateData },
        setErrors,
        resetForm,
        setSubmitting,
      },
    ) => {
      const { useTypes, regions, purchaseTypes, ...rest } = values;
      const input = {
        ...rest,
        id: organisation.id,
        regionIds: regions
          ? regions.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
        useTypeIds: useTypes
          ? useTypes.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
        purchaseTypeIds: purchaseTypes
          ? purchaseTypes.filter(({ value }) => value).map(({ id }) => id)
          : undefined,
      };
      try {
        await updateOrganisation.mutate(input);
        setSubmitting(false);
        resetForm();
        if (onUpdateData) onUpdateData();
      } catch (errs) {
        console.error(
          'Error while trying to update organisation investor profile',
          errs,
        );
        setSubmitting(false);
        const errors = parseErrorsLegacy(errs, values);
        console.error(
          'Parsed Error while trying to update organisation investor profile',
          errors,
          errs,
        );

        setErrors(errors);
      }
    },
  }),
  withProps(({ errors, organisation }) => ({
    isNewOrganisation: !organisation,
    // YUP provide errors for subproperties like { 'language.code': 'blabla' }
    // so to not complicate render transform such errors into {language: 'blabla'}
    errors: mapKeys(key => key.split('.')[0], errors),
  })),
  withHandlers({
    // https://github.com/jaredpalmer/formik/issues/114 will be fixed soon
    handleChange: ({ handleChange, handleBlur, touched }) => e => {
      handleChange(e);

      if (!touched[e.target.id]) {
        Promise.resolve().then(() => handleBlur(e));
      }
    },
  }),
);

export default organisationDetailsInvestorProfileFormHOC(
  organisationDetailsInvestorProfileForm,
);
