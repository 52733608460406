/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DictionaryItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DictionaryList_items$ref: FragmentReference;
declare export opaque type DictionaryList_items$fragmentType: DictionaryList_items$ref;
export type DictionaryList_items = $ReadOnlyArray<{|
  +id: string,
  +position: ?number,
  +group?: ?string,
  +$fragmentRefs: DictionaryItem_item$ref,
  +$refType: DictionaryList_items$ref,
|}>;
export type DictionaryList_items$data = DictionaryList_items;
export type DictionaryList_items$key = $ReadOnlyArray<{
  +$data?: DictionaryList_items$data,
  +$fragmentRefs: DictionaryList_items$ref,
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "group",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "DictionaryList_items",
  "type": "Dictionary",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "position",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "PipelineStage",
      "selections": (v0/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "DealStatus",
      "selections": (v0/*: any*/)
    },
    {
      "kind": "InlineFragment",
      "type": "FileType",
      "selections": (v0/*: any*/)
    },
    {
      "kind": "FragmentSpread",
      "name": "DictionaryItem_item",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '48d33608cec3c53be0b2726896e06146';
module.exports = node;
