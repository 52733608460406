// @flow

import * as React from 'react';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Divider,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  Popover,
  ListItemSecondaryAction,
  CircularProgress,
  Box,
  Button,
  ListItem,
  MenuList,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-navi';
import { useFragment, graphql } from 'relay-hooks';

import UserAvatar from './UserAvatar';
import config from '../config';
import { UserInput } from '../controls/user-input';
import ButtonProgress from './ButtonProgress';
import { useMutation } from '../controls/relay';

import { type userMenu_viewer } from './__generated__/userMenu_viewer.graphql';
import { type userMenuLoginAsMutation } from './__generated__/userMenuLoginAsMutation.graphql';
import { type userMenuLogoutAsMutation } from './__generated__/userMenuLogoutAsMutation.graphql';

const fetchOptions = { method: 'POST', credentials: 'include' };

function handleLogout() {
  Promise.all([
    fetch(`${config.api.clientUrl}/login/clear`, fetchOptions),
    config.api.clientUrl !== undefined
      ? fetch('/logout', fetchOptions)
      : Promise.resolve(),
  ]).then(() => {
    window.location.href = '/';
  });
}

const fragmentViewer = graphql`
  fragment userMenu_viewer on User {
    ...UserAvatar_user
    id
    fullName
    isLoggedInAs
    isAdmin
    isSuperAdmin
    realUser {
      ...UserAvatar_user
      id
      fullName
    }
  }
`;

type Props = {|
  viewer: userMenu_viewer,
  refetch: () => void,
|};

export const UserMenu = (props: Props) => {
  const theme = useTheme();
  const viewer = useFragment(fragmentViewer, props.viewer);
  const popupState = usePopupState({ variant: 'popover', popupId: 'useMenu' });
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [loginAsOpen, setLoginAsOpen] = React.useState(false);
  const [
    loginAsUser,
    { loading: loginAsUserLoading },
  ] = useMutation<userMenuLoginAsMutation>(
    graphql`
      mutation userMenuLoginAsMutation($input: LoginAsUserInput!) {
        loginAsUser(input: $input) {
          result
          viewer {
            ...userMenu_viewer
          }
        }
      }
    `,
    {
      onCompleted: response => {
        if (response.loginAsUser) {
          setLoginAsOpen(false);
          props.refetch();
        }
      },
    },
  );
  const [
    logoutAsUser,
    { loading: logoutAsUserLoading },
  ] = useMutation<userMenuLogoutAsMutation>(
    graphql`
      mutation userMenuLogoutAsMutation($input: LogoutAsUserInput!) {
        logoutAsUser(input: $input) {
          result
          viewer {
            ...userMenu_viewer
          }
        }
      }
    `,
    {
      onCompleted: () => props.refetch(),
    },
  );
  const isAdminPath = window.location.pathname.startsWith('/admin');

  return (
    <>
      <Dialog
        open={loginAsOpen}
        fullWidth={true}
        maxWidth="xs"
        onBackdropClick={() => setLoginAsOpen(false)}
      >
        <DialogTitle>Login as user</DialogTitle>
        <DialogContent>
          <DialogContentText>You will be logged as</DialogContentText>
          <Box>
            <UserInput
              value={selectedUser}
              onChange={setSelectedUser}
              variant="outlined"
              label="Choose user"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loginAsUserLoading}
            onClick={() => setLoginAsOpen(false)}
          >
            Cancel
          </Button>
          <ButtonProgress
            disabled={selectedUser == null}
            loading={loginAsUserLoading}
            color="primary"
            onClick={() =>
              selectedUser && loginAsUser({ userId: selectedUser.id })
            }
          >
            Log in
          </ButtonProgress>
        </DialogActions>
      </Dialog>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          {!isAdminPath && [
            <MenuItem component={Link} href="/profile" key="profile">
              Account settings
            </MenuItem>,
            <Divider key="divider1" />,
          ]}
          {(viewer.isSuperAdmin || viewer.isLoggedInAs) && (
            <MenuItem onClick={() => setLoginAsOpen(true)}>Login as</MenuItem>
          )}
          {viewer.realUser != null && [
            viewer.isLoggedInAs && <Divider key="divider2" />,
            <ListItem
              button
              disabled={logoutAsUserLoading}
              onClick={() => logoutAsUser({})}
              key="logout"
            >
              <ListItemAvatar>
                <UserAvatar user={viewer.realUser} />
              </ListItemAvatar>
              <ListItemText
                primary="Return to account"
                secondary={viewer.realUser.fullName}
              />
              <ListItemSecondaryAction>
                {logoutAsUserLoading && <CircularProgress size={24} />}
              </ListItemSecondaryAction>
            </ListItem>,
            <Divider key="divider3" />,
          ]}
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </MenuList>
      </Popover>
      <Button {...bindTrigger(popupState)}>
        {viewer.realUser != null && (
          <UserAvatar
            user={viewer.realUser}
            size={36}
            css={`
              position: relative;
              z-index: -1;
              filter: grayscale(1);
              margin-right: -15px;
              border: 1px ${theme.palette.primary.main} solid;
            `}
          />
        )}
        <UserAvatar
          user={viewer}
          size={36}
          css={`
            border: 1px ${theme.palette.primary.main} solid;
            background: #fff;
          `}
        />
      </Button>
    </>
  );
};
