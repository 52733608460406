import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { graphql } from 'relay-runtime';
import { wrapDisplayName } from 'recompose';

import options from './options';

const { fallbackLng, whitelist } = options;

/*
 * Provides a helper function for changing user's language. For example:
 *
 *   const FrenchButton = withChangeLanguage(({ changeLanguage }) => {
 *     return <button data-lng="fr" onClick={changeLanguage}>FR</button>;
 *   });
 */

function getPath(currentLng, nextLng, path) {
  let urlLng = path.split('/')[1];
  urlLng = whitelist.includes(urlLng) ? urlLng : '';
  let pathname = path;

  if (urlLng === nextLng || (!urlLng.length && nextLng === fallbackLng))
    pathname = path;
  else if (nextLng === fallbackLng)
    pathname = `${path.slice(currentLng.length + 1)}`;
  else
    pathname = `${nextLng}${path.slice(urlLng.length ? urlLng.length + 1 : 0)}`;

  if (pathname[0] !== '/') pathname = `/${pathname}`;

  return pathname;
}

function withChangeLanguage() {
  return ComposedComponent =>
    class extends React.Component {
      static contextTypes = {
        api: PropTypes.object.isRequired,
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
        }),
        router: PropTypes.shape({
          history: PropTypes.shape({
            push: PropTypes.func.isRequired,
          }).isRequired,
        }),
      };

      static displayName = wrapDisplayName(
        ComposedComponent,
        'withChangeLanguage',
      );

      changeLanguage = (event, viewer) => {
        const { api } = this.context;
        const { i18n } = this.props;
        const language = typeof event === 'string' ? event : event.target.value;
        // const history = router ? router.history : this.context.history;

        if (!i18n.options.whitelist.includes(language)) {
          throw new Error(`The "${language}" language is not supported.`);
        }

        if (i18n.options.language === language) {
          return;
        }

        Cookies.set('lng', language, { expires: 60 });

        // Reload the page

        if (viewer) {
          // Attempt to store the language (code) in user's profile
          api.commitMutation({
            mutation: graphql`
              mutation withChangeLanguageMutation($input: UpdateUserInput!) {
                updateUser(input: $input) {
                  user {
                    id
                    language
                  }
                  clientMutationId
                }
              }
            `,
            variables: { input: { language, id: viewer.id } },
          });
        }
        const pathname = getPath(
          i18n.language,
          language,
          document.location.pathname,
        );

        i18n.changeLanguage(language, () => {
          document.location = pathname; // TODO: replace with history.pushState
        });
      };

      render() {
        return (
          <ComposedComponent
            changeLanguage={this.changeLanguage}
            {...this.props}
          />
        );
      }
    };
}

export default withChangeLanguage;
