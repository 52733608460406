import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { compose, defaultProps, getContext } from 'recompose';
import Loading from '../controls/Loading';

export const LoadableDashboard = Loadable({
  loader: () => import(/* webpackChunkName: 'Deals' */ './DashboardView'),
  loading: Loading,
});

const dashboardQueryRenderer = ({ api: { environment }, query }) => (
  <QueryRenderer
    cacheConfig={{ cacheFirst: true }}
    environment={environment}
    query={query}
    variables={{ count: 5 }}
    render={({ error, props }) => {
      if (error) return <div>{error.message}</div>;

      // provide null so internal routes can be loaded even with some defaults
      return <LoadableDashboard root={props || null} />;
    }}
  />
);

export default compose(
  defaultProps({
    query: graphql`
      query DashboardQuery {
        ...DashboardView_root
      }
    `,
  }),
  getContext({
    api: PropTypes.object,
  }),
)(dashboardQueryRenderer);
