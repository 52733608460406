// @flow

import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import { useClickOutsideObserver } from '../realadvisor-packages/observe';
import { AnimChip } from './anim-chip.js';

type Props = {|
  label: string,
  empty?: boolean,
  open: boolean,
  onOpenChange: boolean => void,
  onClear?: () => void,
  children: React.Node,
|};

const PopoverContent = ({ targetRef, onClose, children }) => {
  const contentRef = React.useRef(null);
  useClickOutsideObserver([targetRef, contentRef], onClose);
  return <div ref={contentRef}>{children}</div>;
};

export const SelectPopover = (props: Props): React.Node => {
  const { empty = true, open, onOpenChange, onClear, label, children } = props;
  const targetRef = React.useRef(null);

  return (
    <>
      <div ref={targetRef}>
        <AnimChip
          label={label}
          active={open || !empty}
          onClick={() => {
            onOpenChange(!open);
          }}
          onDelete={() => {
            if (!empty) {
              if (onClear != null) {
                onClear();
                onOpenChange(false);
              }
            } else {
              onOpenChange(!open);
            }
          }}
          empty={empty}
        />
      </div>

      <Popover
        // Remove backdrop events as it prevents click on other Select Item
        // because of that we use custom click-outside control
        css={{
          pointerEvents: 'none',
          '> div': {
            pointerEvents: 'auto',
          },
        }}
        hideBackdrop={true}
        anchorEl={() => targetRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        transitionDuration={250}
        open={open}
        onClose={() => onOpenChange(false)}
        TransitionProps={{
          onEntered: () => {
            // this solves the problem with incorrectly positioned tab indicator
            // which measures position before transition is completed
            // https://github.com/mui-org/material-ui/issues/9337#issuecomment-413789329
            window.dispatchEvent(new CustomEvent('resize'));
          },
        }}
      >
        <PopoverContent
          targetRef={targetRef}
          onClose={() => onOpenChange(false)}
        >
          {children}
        </PopoverContent>
      </Popover>
    </>
  );
};
