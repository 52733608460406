import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { compose, defaultProps, getContext } from 'recompose';
import Loading from '../controls/Loading';

export const LoadableUsersList = Loadable({
  loader: () => import(/* webpackChunkName: 'Users' */ './UsersList'),
  loading: Loading,
});

const usersQueryRenderer = ({ api: { environment }, query, filters }) => (
  <QueryRenderer
    // cacheConfig={{ cacheFirst: true }}
    environment={environment}
    query={query}
    variables={{ count: 80, filters }}
    render={({ error, props }) => {
      if (error) return <div>{error.message}</div>;

      // provide null so internal routes can be loaded even with some defaults
      return <LoadableUsersList root={props || null} filters={filters} />;
    }}
  />
);

export default compose(
  defaultProps({
    query: graphql`
      query UsersQuery(
        $count: Int!
        $cursor: String
        $sortBy: String
        $sortDirection: SORT_DIRECTION
        $filters: UserFilters
      ) {
        ...UsersList_root
      }
    `,
    csvQuery: graphql`
      query UsersCsvQuery(
        $count: Int!
        $cursor: String
        $sortBy: String
        $sortDirection: SORT_DIRECTION
        $filters: UserFilters
      ) {
        ...UsersList_root
      }
    `,

    filters: {
      organisations: [],
      cities: [],
      jobTitles: [],
      languages: [],
      isActive: false,
      isAdmin: false,
    },
  }),
  getContext({
    api: PropTypes.object,
  }),
)(usersQueryRenderer);
