/**
 * @flow
 * @relayHash 25dcd7efb534ec545c3d2889b7e34aa5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layoutv2_viewer$ref = any;
export type USER_LANGUAGE = "de" | "en" | "fr" | "it" | "%future added value";
export type adminQueryVariables = {||};
export type adminQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +language: ?USER_LANGUAGE,
    +isAdmin: boolean,
    +isBroker: ?boolean,
    +isSeller: ?boolean,
    +isInvestor: ?boolean,
    +isSuperAdmin: boolean,
    +organisation: ?{|
      +id: string,
      +isInvestor: ?boolean,
      +isSeller: ?boolean,
      +isBroker: ?boolean,
    |},
    +$fragmentRefs: Layoutv2_viewer$ref,
  |}
|};
export type adminQuery = {|
  variables: adminQueryVariables,
  response: adminQueryResponse,
|};
*/


/*
query adminQuery {
  viewer {
    id
    language
    isAdmin
    isBroker
    isSeller
    isInvestor
    isSuperAdmin
    ...Layoutv2_viewer
    organisation {
      id
      isInvestor
      isSeller
      isBroker
    }
  }
}

fragment Layoutv2_viewer on User {
  ...LeftNavigation_viewer
  ...userMenu_viewer
}

fragment LeftNavigation_viewer on User {
  ...LanguagePicker_viewer
  id
  isAdmin
  isSuperAdmin
  isBroker
  isInvestor
  isSeller
}

fragment userMenu_viewer on User {
  ...UserAvatar_user
  id
  fullName
  isLoggedInAs
  isAdmin
  isSuperAdmin
  realUser {
    ...UserAvatar_user
    id
    fullName
  }
}

fragment UserAvatar_user on User {
  id
  firstName
  lastName
  fullName
  primaryImage {
    url
    id
  }
}

fragment LanguagePicker_viewer on User {
  id
  language
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdmin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isBroker",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSeller",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isInvestor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperAdmin",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "organisation",
  "storageKey": null,
  "args": null,
  "concreteType": "Organisation",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "primaryImage",
  "storageKey": null,
  "args": null,
  "concreteType": "Image",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "adminQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Layoutv2_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "adminQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isLoggedInAs",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "realUser",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "adminQuery",
    "id": null,
    "text": "query adminQuery {\n  viewer {\n    id\n    language\n    isAdmin\n    isBroker\n    isSeller\n    isInvestor\n    isSuperAdmin\n    ...Layoutv2_viewer\n    organisation {\n      id\n      isInvestor\n      isSeller\n      isBroker\n    }\n  }\n}\n\nfragment Layoutv2_viewer on User {\n  ...LeftNavigation_viewer\n  ...userMenu_viewer\n}\n\nfragment LeftNavigation_viewer on User {\n  ...LanguagePicker_viewer\n  id\n  isAdmin\n  isSuperAdmin\n  isBroker\n  isInvestor\n  isSeller\n}\n\nfragment userMenu_viewer on User {\n  ...UserAvatar_user\n  id\n  fullName\n  isLoggedInAs\n  isAdmin\n  isSuperAdmin\n  realUser {\n    ...UserAvatar_user\n    id\n    fullName\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  fullName\n  primaryImage {\n    url\n    id\n  }\n}\n\nfragment LanguagePicker_viewer on User {\n  id\n  language\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a44f28815af1ae9099d79cf83d5e1736';
module.exports = node;
