// @flow

import * as React from 'react';
import { Chip, SvgIcon, useTheme } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { mdiCloseCircleOutline } from '@mdi/js';
import styled from 'styled-components';

type AnimChipIconsProps = {|
  empty: boolean,
  timeout?: number,
  onClick?: (e: SyntheticEvent<EventTarget>) => void,
|};

const StyledChip = styled(Chip)`
  box-shadow: 0px 3px 10px rgba(40, 99, 166, 0.15);
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 8.5px;
`;

const IconHolder = ({ visible, timeout, children }) => {
  return (
    <div
      css={{
        lineHeight: 0,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: visible ? 1 : 0,
        transition: `opacity ${timeout}ms ease`,
      }}
    >
      {children}
    </div>
  );
};

export const AnimChipIcons = ({
  empty,
  timeout = 250,
  onClick,
}: AnimChipIconsProps): React.Node => (
  <div
    css={`
      line-height: 0;
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 3px;
    `}
    onClick={onClick}
  >
    <IconHolder visible={empty === true} timeout={timeout}>
      <ExpandMore />
    </IconHolder>
    <IconHolder visible={empty === false} timeout={timeout}>
      <SvgIcon>
        <path d={mdiCloseCircleOutline} />
      </SvgIcon>
    </IconHolder>
  </div>
);

type Props = {|
  label: string,
  empty?: boolean,
  active?: boolean,
  onClick?: (e: SyntheticEvent<EventTarget>) => void,
  onDelete?: (e: SyntheticEvent<EventTarget>) => void,
|};

export const AnimChip = ({
  label,
  empty = true,
  active = false,
  onClick,
  onDelete = () => {},
}: Props): React.Node => {
  return (
    <StyledChip
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<AnimChipIcons empty={empty} />}
      variant="outlined"
      color={active ? 'primary' : 'default'}
    />
  );
};
