/**
 * @flow
 * @relayHash 1802960ed66656643a73f332805f9665
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DictionaryItem_item$ref = any;
export type UpdatePipelineStageInput = {|
  id: string,
  labelEn?: ?string,
  labelFr?: ?string,
  labelDe?: ?string,
  position?: ?number,
  clientMutationId?: ?string,
|};
export type pipelineStagesUpdatePipelineStageMutationVariables = {|
  input: UpdatePipelineStageInput
|};
export type pipelineStagesUpdatePipelineStageMutationResponse = {|
  +updatePipelineStage: ?{|
    +node: ?{|
      +$fragmentRefs: DictionaryItem_item$ref
    |}
  |}
|};
export type pipelineStagesUpdatePipelineStageMutation = {|
  variables: pipelineStagesUpdatePipelineStageMutationVariables,
  response: pipelineStagesUpdatePipelineStageMutationResponse,
|};
*/


/*
mutation pipelineStagesUpdatePipelineStageMutation(
  $input: UpdatePipelineStageInput!
) {
  updatePipelineStage(input: $input) {
    node {
      ...DictionaryItem_item
      id
    }
  }
}

fragment DictionaryItem_item on Dictionary {
  __typename
  id
  label
  position
  labelEn: label(lang: "en")
  labelFr: label(lang: "fr")
  labelDe: label(lang: "de")
  ... on PipelineStage {
    group
  }
  ... on DealStatus {
    group
  }
  ... on FileType {
    group
    type
    adminOnlyAccess
    pipelineStageAccess {
      SELECTED_NOTIFIED
      INTERESTED
      NDA_SIGNED
      NBO_SUBMITTED
      VISIT
      DUE_DILIGENCE
      BO_SUBMITTED
      CLOSING
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePipelineStageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "pipelineStagesUpdatePipelineStageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePipelineStage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePipelineStagePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "PipelineStage",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "DictionaryItem_item",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "pipelineStagesUpdatePipelineStageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePipelineStage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePipelineStagePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "PipelineStage",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "label",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "position",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": "labelEn",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "en"
                  }
                ],
                "storageKey": "label(lang:\"en\")"
              },
              {
                "kind": "ScalarField",
                "alias": "labelFr",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "fr"
                  }
                ],
                "storageKey": "label(lang:\"fr\")"
              },
              {
                "kind": "ScalarField",
                "alias": "labelDe",
                "name": "label",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "lang",
                    "value": "de"
                  }
                ],
                "storageKey": "label(lang:\"de\")"
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "group",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "type": "FileType",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "adminOnlyAccess",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pipelineStageAccess",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FileTypePipelineStageAccess",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "SELECTED_NOTIFIED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "INTERESTED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NDA_SIGNED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "NBO_SUBMITTED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "VISIT",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "DUE_DILIGENCE",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "BO_SUBMITTED",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "CLOSING",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "pipelineStagesUpdatePipelineStageMutation",
    "id": null,
    "text": "mutation pipelineStagesUpdatePipelineStageMutation(\n  $input: UpdatePipelineStageInput!\n) {\n  updatePipelineStage(input: $input) {\n    node {\n      ...DictionaryItem_item\n      id\n    }\n  }\n}\n\nfragment DictionaryItem_item on Dictionary {\n  __typename\n  id\n  label\n  position\n  labelEn: label(lang: \"en\")\n  labelFr: label(lang: \"fr\")\n  labelDe: label(lang: \"de\")\n  ... on PipelineStage {\n    group\n  }\n  ... on DealStatus {\n    group\n  }\n  ... on FileType {\n    group\n    type\n    adminOnlyAccess\n    pipelineStageAccess {\n      SELECTED_NOTIFIED\n      INTERESTED\n      NDA_SIGNED\n      NBO_SUBMITTED\n      VISIT\n      DUE_DILIGENCE\n      BO_SUBMITTED\n      CLOSING\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bc9c10516cc26c0bb17ab347be01146';
module.exports = node;
