// @flow

import * as React from 'react';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { graphql } from 'react-relay';
import { withTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import { MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { getLangFlag } from '../../utils';
import { withChangeLanguage, languages } from '../../i18n';
import { createFragmentContainerHOC } from '../../utils/relay';
import DropdownButton from '../ButtonMenu';

const LangFlagIconRoot = styled.span`
  display: inline-block;
  position: relative;
  height: 24px;
  > img {
    position: absolute;
    height: 12px;
    width: 16px;
    bottom: 0;
    right: -4px;
  }
`;

const LangFlagIcon = ({ language }) => (
  <LangFlagIconRoot>
    <LanguageIcon />
    {!!language && <img src={getLangFlag(language)} alt={language} />}
  </LangFlagIconRoot>
);

const Root = styled.div`
  display: inline-block;
`;

const languagePicker = ({ viewer, changeLanguage, i18n, ...props }) => (
  <Root {...props}>
    <DropdownButton icon={<LangFlagIcon language={i18n?.language} />}>
      {languages.map(({ value, label }) => (
        <MenuItem
          value={value}
          key={value}
          onClick={() => {
            changeLanguage(value, viewer);
          }}
        >
          <ListItemIcon>
            <img
              src={getLangFlag(value)}
              alt={value}
              style={{ width: 'auto', height: '1em' }}
            />
          </ListItemIcon>
          <ListItemText inset primary={label} />
        </MenuItem>
      ))}
    </DropdownButton>
  </Root>
);

export default compose(
  withTranslation('common'),
  withChangeLanguage(),
  createFragmentContainerHOC({
    viewer: graphql`
      fragment LanguagePicker_viewer on User {
        id
        language
      }
    `,
  }),
)(languagePicker);
