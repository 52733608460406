/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation as translate } from 'react-i18next';
// import Divider from '@material-ui/core/Divider';
import styled, { css } from 'styled-components';
import {
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import BusinessIcon from '@material-ui/icons/Business';
import Icon from '@mdi/react';
import { mdiViewDashboardVariant } from '@mdi/js';
import SettingsIcon from '@material-ui/icons/Settings';
import People from '@material-ui/icons/People';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { graphql } from 'react-relay';
// import withWidth, { isWidthDown } from '@material-ui/core/utils/withWidth';

import { uniMaterial } from '../../routes/admin/controls/UniStyled';
import { LoadableOrganisationsList } from '../../routes/admin/Organisations';
import { LoadableUsersList } from '../../routes/admin/Users';
import { LoadableDealsList } from '../../routes/admin/Deals';
import { LoadableDashboard } from '../../routes/admin/Dashboard';
import { createFragmentContainerHOC } from '../../utils/relay';
import LanguagePicker from '../LanguagePicker';
import { useLink } from '../../hooks/router';

const FakeAppBar = styled(uniMaterial(AppBar))`
  background-color: white;
  box-shadow: unset;
  color: black;
  > div {
    margin-left: auto;
  }
`;

export const LeftMenu = styled(Drawer)`
  background-color: white;
  z-index: 2;
  > div {
    overflow-x: hidden;
  }

  ${({ open, ...p }) =>
    open
      ? css`
          width: ${p['data-max-w']}px;
        `
      : css`
          width: ${p['data-min-w']}px;
          @media (max-width: 575px) {
            width: 0;
          }
        `};
  ${p =>
    p['data-position'] === 'fixed'
      ? css`
          position: fixed;
          left: 0;
          bottom: 0;
          top: 0;
        `
      : css`
          position: relative;
          @media (max-width: 575px) {
            /* don't move content on mobile */
            width: 0;
          }
        `};
  transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: unset;
  }
`;

const MyIconButton = styled(IconButton)`
  margin-right: -16px;
  margin-left: auto;
`;

const MenuList = styled(List)`
  margin-top: -8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LinkText = styled(ListItemText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FlexibleSpacer = styled.div`
  flex: 1;
`;

const ULanguagePicker = styled(LanguagePicker)`
  margin-top: -12px;
  margin-left: -12px;
  margin-right: 24px;
`;

const ListItemLink = props => <ListItem {...props} component={Link} />;

const leftNavigation = ({
  open,
  onClose,
  position,
  navMenuMinWidth,
  navMenuMaxWidth,
  viewer,
  t,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const conditionalClose = () => (isMobile ? onClose() : null);

  const hardcodedStatusesString = `&dealStatusId_in=RGVhbFN0YXR1czo1MGNhZmM3MC04YmFlLTQ2OWMtOTMzNi1jYjE2NmU3YTBlOWI%253D&dealStatusId_in=RGVhbFN0YXR1czpiNzkwMGY5NS1iNGRkLTRjMWMtYjI5OC04YjJhZWE4ZmZjZTc%253D&dealStatusId_in=RGVhbFN0YXR1czo3NGM4MjhjMS1iYmY4LTQwNWMtYjhlYy1lYWM4M2U2Zjc5Zjc%253D&sortBy=activity&sortDirection=DESC`;
  const viewerString = viewer?.isSuperAdmin ? '' : `&brokerId_in=${viewer?.id}`;
  const dealsTo = `/deals/?view=list${viewerString}${hardcodedStatusesString}`;
  // deprecated react router isn't compatible with our useSearchParams hook, so as a temporary hack onClick is duplicated with `to`
  const link = useLink(`/admin${dealsTo}`);

  return (
    <div>
      <LeftMenu
        data-min-w={navMenuMinWidth}
        data-max-w={navMenuMaxWidth}
        open={open}
        anchor="left"
        variant="permanent"
      >
        <div />
      </LeftMenu>

      <LeftMenu
        open={open}
        anchor="left"
        variant="permanent"
        data-min-w={navMenuMinWidth}
        data-max-w={navMenuMaxWidth}
        data-position={position}
        onClose={onClose}
      >
        {/* use copy of original element to not implement media queries
          as even media will change Fake will preserve the same sizes
          as real element */}
        <FakeAppBar position="static">
          <Toolbar>
            <MyIconButton
              data-open={open}
              color="default"
              aria-label="Menu"
              onClick={onClose}
            >
              <LeftIcon />
            </MyIconButton>
          </Toolbar>
        </FakeAppBar>
        <MenuList>
          <ListItemLink
            // no need to wait for a click, we can preload on hover
            onMouseEnter={() => LoadableDashboard.preload()}
            button
            to="/"
            onClick={conditionalClose}
          >
            <ListItemIcon>
              <SvgIcon>
                <Icon path={mdiViewDashboardVariant} size={24} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={t('dashboard')}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItemLink>

          <ListItemLink
            // no need to wait for a click, we can preload on hover
            onMouseEnter={() => LoadableOrganisationsList.preload()}
            button
            to="/organisations"
            onClick={conditionalClose}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('organisations')}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItemLink>

          <ListItemLink
            button
            to="/individuals"
            onMouseEnter={() => LoadableUsersList.preload()}
            onClick={conditionalClose}
          >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText
              primary={t('users')}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItemLink>

          {!viewer?.isInvestor && (
            <ListItemLink
              button
              to={dealsTo}
              onMouseEnter={() => LoadableDealsList.preload()}
              onClick={() => {
                link.navigate();
                conditionalClose();
              }}
            >
              <ListItemIcon>
                <CardTravelIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('deals')}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItemLink>
          )}

          <Divider />

          {viewer?.isSuperAdmin && (
            <ListItemLink button to="/settings" onClick={conditionalClose}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('settings')}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItemLink>
          )}

          <FlexibleSpacer />

          <Divider />

          <ListItem button href="/" component="a">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <LinkText
              primary={t('backToPortal')}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <ULanguagePicker viewer={viewer} />
            </ListItemIcon>
            <LinkText primary={t('language')} />
          </ListItem>
        </MenuList>
      </LeftMenu>
    </div>
  );
};

export default compose(
  translate(),
  createFragmentContainerHOC({
    viewer: graphql`
      fragment LeftNavigation_viewer on User {
        ...LanguagePicker_viewer
        id
        isAdmin
        isSuperAdmin
        isBroker
        isInvestor
        isSeller
      }
    `,
  }),
)(leftNavigation);
